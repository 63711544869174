import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState, useContext } from "react"
import { Select, Space, message, Spin, Switch } from "antd"
import api from "api"
import { get } from "utils/fetch"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { USDollar } from "utils/format"
import { isPhoneSize, isAdmin, propertyToAddress, downloadCSV } from "utils/util"
import { Email } from "pages/Email/Email"
import { GlobalContext } from "GlobalContext";
import { FundSubtypeTableView } from "TableViews/FundSubtypeTableView"

export function FundInsertion(props) {

    // const { fund, setFundId, holdingCompanies } = props
    const { setManageRecordId, manageRecordId, setManageRecordTab, accessToken, globalId } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [dirty, setDirty] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [saving, setSaving] = useState(false)
    const [holdingCompaniesInvested, setHoldingCompaniesInvested] = useState([]) // companies invested before opening this page
    const [name, setName] = useState("")
    const [type, setType] = useState("")
    const [companyIdxSelected, setCompanyIdxSelected] = useState([])
    const [accountsByFund, setAccountsByFund] = useState([])
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    // holding company -> percentage (how much percent of the company is fund investing)
    const [investingPercent, setInvestingPercent] = useState({})
    const [investorsByFund, setInvestorsByFund] = useState([])
    const [propertiesByFund, setPropertiesByFund] = useState([])
    const [positionsByFund, setPositionsByFund] = useState([])
    const [view, setView] = useState("fund")
    const [emailResponse, setEmailResponse] = useState("")
    const [fund, setFund] = useState(null)
    const [holdingCompanies, setHoldingCompanies] = useState([])
    const [companyDisplay, setCompanyDisplay] = useState([])
    const [isOffering, setIsOffering] = useState(false)
    const [accountIdToInvestors, setAccountIdToInvestors] = useState({})
    // set of types in current position
    const [typeSet, setTypeSet] = useState(new Set())
    const [editMode, setEditMode] = useState(manageRecordId === -1 ? true : false)

    useEffect(() => {
        const companyIdsInvested = holdingCompaniesInvested.map((company, idx) => company.id)
        // console.log(propertyIdsInvested)
        setInvestingPercent(
            companyIdxSelected.reduce((acc, cur) => {
                if (cur in investingPercent) {
                    acc[cur] = investingPercent[cur]
                } else if (companyIdsInvested.includes(cur)) {
                    acc[cur] = holdingCompaniesInvested.filter(company => company.id === cur)[0].share_of_company === null ? 0 : holdingCompaniesInvested.filter(company => company.id === cur)[0].share_of_company
                } else {
                    acc[cur] = 0
                }
                return acc
            }, {})
        )
    }, [companyIdxSelected])

    useEffect(() => {
        var accountIdToInvestors = {}
        var typeSet = new Set()
        for (const entry of positionsByFund) {
            const curAccountId = entry.account_id
            const curInvestors = entry.investors
            accountIdToInvestors[curAccountId] = curInvestors

            const type = entry.type
            if (!type) continue
            typeSet.add(type)
        }
        setTypeSet(typeSet)
        setAccountIdToInvestors(accountIdToInvestors)
    }, [positionsByFund])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchHoldingCompaniesInvested(id) {
        // console.log("id", id)
        await get(api.manager.HoldingCompaniesByFund(id), setHoldingCompaniesInvested, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestorsByFund(id) {
        await get(api.manager.investorsByFund(id) + `?sale_id=${globalId}`, setInvestorsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchPropertiesByFund(id) {
        await get(api.manager.propertiesByFund(id), setPropertiesByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchPositionsByFund(id) {
        await get(api.manager.positionsByFund(id) + `?sale_id=${globalId}`, setPositionsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchAccounts(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.accountsByFund(id) + `?sale_id=${globalId}`, setAccountsByFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
        setLoading(false)
    }
    // console.log(holdingCompaniesInvested)
    useEffect(() => {
        setCompanyIdxSelected(holdingCompaniesInvested.map(holdingCompaniesInvested => holdingCompaniesInvested.id))
        setCompanyDisplay(holdingCompaniesInvested.map(holdingCompany => holdingCompany.name))
    }, [holdingCompaniesInvested])

    async function fetchRelatedData() {
        await fetchInvestorsByFund(fund.id)
        await fetchPropertiesByFund(fund.id)
        await fetchPositionsByFund(fund.id)
        await fetchHoldingCompaniesInvested(fund.id)
        await fetchAccounts(fund.id)
    }

    function restore() {
        setName(fund === null || fund.name === null ? "" : fund.name)
        setType(fund === null || fund.type === null ? "" : fund.type)
        setIsOffering(fund.is_offering === null ? false : fund.is_offering)
    }

    useEffect(() => {
        if (manageRecordId < 0) {
            setLoading(false)
            return
        }
        if (fund === null) {
            return
        }
        fetchRelatedData()
        restore()
    }, [fund])

    async function fetchHoldingCompanies() {
        await get(api.manager.holdingCompanies, setHoldingCompanies, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchFund(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.fund(id), setFund, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    useEffect(() => {
        fetchHoldingCompanies()
        if (manageRecordId > 0) {
            fetchFund(manageRecordId)
        }
    }, [])

    var holdingCompany2idx = {}
    for (const holdingCompany of holdingCompanies) {
        holdingCompany2idx[holdingCompany.name] = holdingCompany.id
    }
    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    function canSubmit() {
        var invalidFields = []
        if (name.trim().length === 0) {
            invalidFields.push("Please fill name.")
        }

        if (type.length === 0) {
            invalidFields.push("Please select type.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveProperty() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            // setManageRecordId(0)
            return
        }
        // setSaving(true)
        setName(name.trim())
        // selected in current page
        for (const id of companyIdxSelected) {
            //console.log("selected company id", id)
        }
        // invested before opening the page
        for (const company of holdingCompaniesInvested) {
            //console.log("Invested company id", company.id, "(" + company.name + ")")
        }

        // id invested previously
        const companyIdInvested = holdingCompaniesInvested.length === 0 ? [] : holdingCompaniesInvested.map(company => company.id)
        // newly added company id (selected but not previously invested)
        const addedCompanyId = companyIdxSelected.length === 0 ? [] : companyIdxSelected.filter(id => !companyIdInvested.includes(id));
        // removed company id (previously invested but not selected)
        const removedCompanyId = companyIdInvested.length === 0 ? [] : companyIdInvested.filter(id => !companyIdxSelected.includes(id))

        const keptCompanyIds = companyIdInvested.length === 0 ? [] : companyIdInvested.filter(id => companyIdxSelected.includes(id))

        const body = JSON.stringify({
            name,
            type,
            addedCompanyId,
            removedCompanyId,
            keptCompanyIds,
            investingPercent,
            isOffering
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        var retId = 0
        setLoading(true)
        if (fund === null) {
            const response = await fetch(api.manager.funds, options); // new record
            retId = await response.json()
        } else {
            await fetch(api.manager.fund(fund.id), options); // old record
            retId = manageRecordId
        }

        if (retId === 0) {
            setLoading(false)
            setManageRecordId(0)
            return
        }
        setManageRecordId(retId)
        fetchFund(retId)
        setEditMode(false)
    }
    const handleChange = (companies) => {
        // companies: companies names
        setCompanyIdxSelected(companies.map(company => holdingCompany2idx[company]))
        setDirty(true)
        setCompanyDisplay(companies)
    };
    const selectionOptions = holdingCompanies
        .map(company => {
            return {
                label: company.name,
                value: company.name,
                desc: company.name,
            }
        })

    // ----------------- type selecion -------------------
    const handleChangeType = (type) => {
        // setFundIdSelected(fundNameToId[fund])
        setDirty(true)
        const typeRes = type.sort((a, b) => a < b ? -1 : 1).join("/")
        setType(typeRes)
    };

    const typeSelectionOptions = [{
        label: "Debt",
        value: "Debt",
        desc: "Debt",
    }, {
        label: "Equity",
        value: "Equity",
        desc: "Equity",
    }]
    // console.log(positionsByFund)
    function deleteFund() {
        if (accountsByFund.length > 0 || holdingCompaniesInvested.length > 0) {
            setCreationErrorMessages([
                ...accountsByFund
                    .map(account => {
                        return `Current fund is being invested by ${account.account_name}.`
                    }),
                ...holdingCompaniesInvested
                    .map(company => {
                        return `Current fund is investing ${company.name}.`
                    }),
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            // body
        }
        const result = await fetch(api.manager.fund(fund.id), options);
        const data = await result.json()
        setManageRecordId(0)
    }

    function handleFundOfferingStatusSwitchChange(status) {
        setDirty(true)
        setIsOffering(status)
    }
    // console.log(isOffering)
    // console.log(investorsByFund.map(investor => investor.email))
    // console.log("positionsByFund", positionsByFund)

    // given investments, get one summary row: type, commitment number, equity balance...
    function getOneAggregratedRow(subtype, investments) {
        var curRow = [subtype, ""]
        curRow.push(USDollar.format(investments
            .reduce((acc, cur) => {
                return acc + cur.amount
            }, 0)))

        curRow.push(USDollar.format(investments
            .reduce((acc, cur) => {
                return acc + cur.account_balance
            }, 0)))

        curRow.push(USDollar.format(investments
            .reduce((acc, cur) => {
                return acc + cur.interest_received
            }, 0)))

        curRow.push(USDollar.format(investments
            .reduce((acc, cur) => {
                return acc + cur.principal_returned
            }, 0)))

        return curRow
    }

    // get data of type "type" that will be exported into csv
    function getDataOfType(type, investments) {
        var res = [[type]]
        var subtypeSet = new Set()
        for (const investment of investments) {
            if (!investment.subtype) continue
            subtypeSet.add(investment.subtype)
        }

        [...subtypeSet].map(subtype => {
            // filter out position by subtype
            const investmentSubtype = investments
                .filter(investment => investment.subtype && investment.subtype.toLowerCase() === subtype.toLowerCase())
            res.push(getOneAggregratedRow(subtype, investmentSubtype))
        })
        // get investments with no subtype
        const investmentWithNoSubtype = investments
            .filter(investment => !investment.subtype)

        if (investmentWithNoSubtype.length > 0) {
            res.push(getOneAggregratedRow("Others", investmentWithNoSubtype))
        }

        // get summary row
        res.push(getOneAggregratedRow("Summary", investments))
        res.push([])
        return res
    }

    function downloadFundPositionCSV() {
        var data = [["Account",
            "Investor",
            "Commitment Number",
            "Equity Balance",
            "Distribute Interest",
            "Principal Return",
            "Capital in Date",
            "Overall Interest",
            "Calculated Interest",
            "Type",
            "Subtype"]];

        // get rows of positions
        positionsByFund
            .map(position => {
                var curRow = []
                curRow.push(position.account_name)
                // get investor information
                const curInvestors = accountIdToInvestors[position.account_id].map(investor =>
                    `${investor.legal_name} (${investor.email})`
                ).join('\n')
                curRow.push(curInvestors)
                curRow.push(USDollar.format(position.amount))
                curRow.push(USDollar.format(position.account_balance))
                curRow.push(USDollar.format(position.interest_received))
                curRow.push(USDollar.format(position.principal_returned))
                curRow.push(position.funding_receipt_date.slice(0, 10))
                curRow.push((!position.overall_interest || position.overall_interest < position.interest) ? `${position.interest}%` : `${position.overall_interest}%`)
                curRow.push(`${position.interest}%`)
                curRow.push(position.type)
                curRow.push(position.subtype)
                data.push(curRow)
            })

        // get overall summary
        if (positionsByFund.length > 0) {
            var overallSummaryRow = ["Summary", ""]
            overallSummaryRow.push(USDollar.format(
                positionsByFund.reduce((acc, cur) => {
                    return acc + cur.amount
                }, 0)
            ))

            overallSummaryRow.push(USDollar.format(
                positionsByFund.reduce((acc, cur) => {
                    return acc + cur.account_balance
                }, 0)
            ))

            overallSummaryRow.push(USDollar.format(
                positionsByFund.reduce((acc, cur) => {
                    return acc + cur.interest_received
                }, 0)
            ))

            overallSummaryRow.push(USDollar.format(
                positionsByFund.reduce((acc, cur) => {
                    return acc + cur.principal_returned
                }, 0)
            ))
            data.push(overallSummaryRow)
        }

        data.push([])

        // get rows of each type and subtype
        if (typeSet && typeSet.size > 0) {
            [...typeSet].map(type => {
                // filter out position of current type
                const investments = positionsByFund
                    .filter(position => position.type && position.type.toLowerCase() === type.toLowerCase())

                data = data.concat(getDataOfType(type, investments))
            })
        }

        // get rows of others (undefined) type
        data = data.concat(getDataOfType("Others", positionsByFund
            .filter(position => !position.type)))

        downloadCSV(data, fund.name)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && view === "email" && <Email
            setView={setView}
            emailPassed={[... new Set(investorsByFund.map(investor => investor.email))]}
            fromView="fund"
            setEmailResponse={setEmailResponse}
        />}
        {!loading && view === "fund" && <div>

            {fund !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Fund: ${fund.name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setManageRecordId(0)}
                ></img>
                {/* {fund !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    placeContent: "center"
                }}
                    onClick={e => setView("email")}
                >
                    Email
                </button>} */}

                {manageRecordId > 0 && <button style={{
                    height: "30px",
                    width: "130px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={downloadFundPositionCSV}>Export Positions</button>}
            </div>

            {isAdmin() && manageRecordId > 0 && <div onClick={e => {
                if (editMode) {
                    restore()
                }
                setEditMode(!editMode)
            }}
                style={{
                    width: "fit-content",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "20px"
                }}>
                {editMode ? "Restore" : "Edit"}
            </div>}

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={name}
                    onInput={(e) => {
                        setName(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>Name: {name}</div>}
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                <div>Type{fund === null && <span style={{ color: "red" }}>*</span>}:</div>
                {editMode &&
                    <span>
                        <Select
                            showSearch
                            mode="multiple"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select type"
                            defaultValue={type ? type.split('/') : []}
                            onChange={handleChangeType}
                            options={typeSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>}
                {!editMode && <span>
                    {' ' + type}
                </span>}
            </div>

            <div style={entryStyle}>
                <div style={isPhoneSize() ? {
                    display: "flex", flexDirection: "column"
                } : {
                    display: "flex", flexDirection: "row"
                }}>
                    {editMode && <><div>Companies: </div>
                        <span>
                            <Select
                                mode="multiple"
                                style={isPhoneSize() ? {
                                    width: '300px'
                                } : {
                                    width: '300px', marginLeft: "5px"
                                }}
                                placeholder="select holding companies"
                                value={companyDisplay}
                                onChange={handleChange}
                                options={selectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span></>}

                    {!editMode && <div>
                        Holding Companies:
                        {holdingCompaniesInvested
                            .sort((a, b) => {
                                if (a.name < b.name) {
                                    return -1
                                }
                                return 1
                            })
                            .map((company, idx) => {
                                return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{
                                        // fontSize: "20px",
                                        // margin: "20px",
                                        width: "fit-content",
                                    }}
                                    >
                                        {company.name}
                                    </div>
                                </div>
                            })}
                    </div>}
                </div>
            </div>

            <div style={entryStyle}>
                Investing Percent:
                {companyIdxSelected.map((companyId, idx) => {
                    return <div key={idx} style={isPhoneSize() ? {
                        display: "flex", flexDirection: "column"
                    } : {
                        display: "flex"
                    }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {holdingCompanies.filter((company) => company.id === companyId)[0].name}:
                        </div>
                        <div>
                            {editMode && <><input style={{
                                outline: 'none',
                                border: "none",
                                borderBottom: "1px solid black",
                                background: 'transparent',
                                textAlign: "right"
                            }}
                                value={investingPercent[companyId] !== undefined ? investingPercent[companyId] : ""}
                                onChange={e => {
                                    // Update state with the raw string input value, allowing "."
                                    const value = e.target.value;

                                    // Set the state with the updated value (as a string)
                                    setDirty(true);
                                    setInvestingPercent({ ...investingPercent, [companyId]: value });
                                }}
                                onBlur={e => {
                                    // Convert to float when input loses focus
                                    const value = parseFloat(e.target.value) || 0; // Default to 0 if invalid
                                    setInvestingPercent({ ...investingPercent, [companyId]: value });
                                }}
                            ></input>%</>}

                            {!editMode && <div>{`${investingPercent[companyId] !== undefined ? investingPercent[companyId] : ""}%`}</div>}
                        </div>
                    </div>
                })}
            </div>

            <div style={entryStyle}>
                {editMode && <>Is Offering: <Switch
                    // className="investment-status-switch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={handleFundOfferingStatusSwitchChange}
                    checked={isOffering} /></>}

                {!editMode && <div>Is Offering: {isOffering ? "Yes" : "No"}</div>}
            </div>

            <div style={entryStyle}>
                Positions:
                {/* head */}
                <div style={{
                    overflowX: "scroll",
                    overflow: "auto",
                    scrollbarWidth: "auto"
                }}>
                    <div style={{ width: "1670px" }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Account
                            </div>

                            <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Investor
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Capital in Date
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Overall Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Calculated Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Type
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Subtype
                            </div>
                        </div>
                        {positionsByFund.length > 0 && <div style={{ border: "1px solid black", width: "1670px" }}>
                            {
                                positionsByFund
                                    // .sort((a, b) => {
                                    //     if (a.legal_name < b.legal_name) {
                                    //         return -1
                                    //     }
                                    //     return 1
                                    // })
                                    .map((position, idx) => {
                                        return <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                            <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: 'column' }}>

                                                <div style={{
                                                    color: "blue",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    fontSize: "15px"
                                                }}
                                                    onClick={() => {
                                                        setManageRecordId(position.account_id)
                                                        setManageRecordTab("Accounts")
                                                    }}>
                                                    {position.account_name}
                                                </div>
                                            </div>

                                            <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: "column" }}>
                                                {accountIdToInvestors[position.account_id].map((investor, id) => <div key={id}>
                                                    <div style={{
                                                        color: "blue",
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                        fontSize: "13px"
                                                    }}
                                                        onClick={() => {
                                                            setManageRecordId(investor.investor_id)
                                                            setManageRecordTab("Investors")
                                                        }}>
                                                        {investor.legal_name}
                                                    </div>
                                                    <div style={{ fontSize: "10px" }}>{investor.email}</div>
                                                </div>)}
                                            </div>

                                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.amount)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.account_balance)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.interest_received)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.principal_returned)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {position.funding_receipt_date.slice(0, 10)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {(!position.overall_interest || position.overall_interest < position.interest) ? `${position.interest}%` : `${position.overall_interest}%`}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {`${position.interest}%`}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {position.type}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {position.subtype}
                                            </div>

                                            <div style={{ width: "50px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                <button
                                                    style={{ background: "rgb(17 32 86)", color: "white", height: "30px", borderRadius: "10px", cursor: "pointer" }}
                                                    onClick={() => {
                                                        setManageRecordId(position.investment_id)
                                                        setManageRecordTab("Positions")
                                                    }}>
                                                    Detail
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    )
                            }

                            <div style={{ display: "flex", borderTop: "1px solid black" }}>
                                <div style={{ width: "240px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    Summary
                                </div>

                                {/* <div style={{ width: "120px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>

                                </div> */}

                                <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.amount
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.account_balance
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.interest_received
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByFund.reduce((acc, cur) => {
                                            return acc + cur.principal_returned
                                        }, 0)
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            {[...typeSet].map((type, id) => <div key={id} style={entryStyle}>
                <FundSubtypeTableView type={type} investments={positionsByFund.filter(position => position.type && position.type.toLowerCase() === type.toLowerCase())} />
            </div>)}
            {positionsByFund.filter(position => !position.type).length > 0 &&
                <div style={entryStyle}>
                    <FundSubtypeTableView type={"Others"} investments={positionsByFund.filter(position => !position.type)} />
                </div>
            }


            <div style={entryStyle}>
                Investors:

                {investorsByFund
                    .sort((a, b) => {
                        if (a.legal_name < b.legal_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((investor, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(investor.id)
                                    setManageRecordTab("Investors")
                                }}>
                                {investor.legal_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Accounts:
                {accountsByFund
                    .sort((a, b) => {
                        if (a.account_name < b.account_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((account, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(account.id)
                                    setManageRecordTab("Accounts")
                                }}>
                                {account.account_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Holding Companies:
                {holdingCompaniesInvested
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((company, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(company.id)
                                    setManageRecordTab("Holding Companies")
                                }}>
                                {company.name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Assets:
                {propertiesByFund
                    .sort((a, b) => {
                        if (propertyToAddress(a) < propertyToAddress(b)) {
                            return -1
                        }
                        return 1
                    })
                    .map((property, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(property.id)
                                    setManageRecordTab("Assets")
                                }}>
                                {propertyToAddress(property)}
                            </div>
                        </div>
                    })}
            </div>

            {isAdmin() && !saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveProperty}>
                    Save
                </button>
                {fund !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteFund}
                >
                    Delete
                </button>}
            </div>}
            {isAdmin() && saving && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}>
                    Saving
                </button>
            </div>}
        </div>}
    </>
}