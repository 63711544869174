import React, { useState, useContext, useEffect } from 'react';
import backArrow from "../../assets/img/backArrow.png"
import api from "api";
import { GlobalContext } from 'GlobalContext';

export function Comments(props) {
    const { setView, investmentId } = props;
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState([]);
    const { globalEmail, accessToken } = useContext(GlobalContext);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);

    // Fetch comments when component mounts
    




    async function handleSubmit(e) {
        e.preventDefault(); // Prevent page refresh
        if (!newComment.trim()) return;

        try {
            const response = await fetch(api.manager.comment(investmentId), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    comment: newComment,
                    email: globalEmail,
                    investmentId: investmentId // Include if needed
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to post comment');
            }
            // Clear the input and refresh comments
            setNewComment('');
            fetchComments();
        } catch (error) {
            console.error('Error posting comment:', error);
            alert('Failed to post comment. Please try again.');
        }
    }

    async function deleteComment(id) {
        if (!isPopupOpen) {
            setCommentToDelete(id);
            setIsPopupOpen(true);
            return;

        }


        try {
            const response = await fetch(api.manager.commentId(id), {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete comment');
            }

            setComments(comments.filter(comment => comment.id !== id));
        } catch (error) {
            console.error('Error deleting comment:', error)
        } finally {
            setIsPopupOpen(false);
            setCommentToDelete(null);
        }
    }

    async function fetchComments() {
        try {
            const response = await fetch(api.manager.comment(investmentId), {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch comments');
            }

            const data = await response.json();
            setComments(data.data || []); // Update state with fetched comments'
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    }

    const containerStyle = {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px'
    };

    const headingStyle = {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '24px'
    };

    const formStyle = {
        marginBottom: '32px'
    };

    const textareaStyle = {
        width: '100%',
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        minHeight: '100px',
        marginBottom: '16px',
        fontSize: '16px'
    };

    const buttonStyle = {
        backgroundColor: '#3b82f6',
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px'
    };

    const commentCardStyle = {
        backgroundColor: '#f9fafb',
        padding: '16px',
        borderRadius: '4px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        marginBottom: '16px'
    };

    const commentHeaderStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '8px'
    };

    const authorStyle = {
        fontWeight: 'bold',
        marginBottom: '4px'
    };

    const timestampStyle = {
        fontSize: '14px',
        color: '#666'
    };

    const deleteButtonStyle = {
        backgroundColor: 'transparent',
        border: 'none',
        color: '#ef4444',
        cursor: 'pointer',
        padding: '4px',
        fontSize: '14px'
    };

    const commentTextStyle = {
        fontSize: '16px',
        lineHeight: '1.5'
    };

    const popupStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(8px)', // Background blur effect
            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent overlay
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, // Ensures the popup is on top of other content
        },
        popupContent: {
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            width: '300px',
            textAlign: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transform: 'scale(0.9)', // Initial scale for animation
            opacity: 0, // Initial opacity for animation
            animation: 'popupFadeIn 0.3s forwards',
        },
        button: {
            margin: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease',
        },
        confirmButton: {
            backgroundColor: '#007bff',
            color: 'white',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
        },
    };

    // Add a keyframe animation for the popup
    const keyframesPopupFadeIn = `
    @keyframes popupFadeIn {
        to {
            transform: scale(1);
            opacity: 1;
        }tchc
    }`;

    useEffect(() => {
        fetchComments();
    }, []);


    useEffect(() => {
        const styleSheet = document.createElement('style');
        styleSheet.type = 'text/css';
        styleSheet.innerText = keyframesPopupFadeIn;
        document.head.appendChild(styleSheet);
        return () => {
            document.head.removeChild(styleSheet);
        };
    }, [keyframesPopupFadeIn]);




    return (
        <div style={containerStyle}>
            <div>
                <div style={{ margin: "20px" }}>
                    <img
                        style={{ width: "15px", height: "20px", cursor: "pointer" }}
                        src={backArrow}
                        onClick={() => setView("investment")}
                        alt="Back"
                    />
                </div>
            </div>
            <h1 style={headingStyle}>Comments</h1>

            <form onSubmit={handleSubmit} style={formStyle}>
                <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Write a comment..."
                    style={textareaStyle}
                    required
                />
                <button type="submit" style={buttonStyle}>
                    Post Comment
                </button>
            </form>

            {/* Display comments from state */}
            <div>
                {comments.map((comment, index) => (
                    <div key={index} style={commentCardStyle}>
                        <div style={commentHeaderStyle}>
                            <div>
                                <h3 style={authorStyle}>{comment.email}</h3>
                                <p style={timestampStyle}>{comment.timestamp}</p>
                            </div>

                            <button onClick={() => deleteComment(comment.id)} style={deleteButtonStyle}>
                                ✖
                            </button>


                        </div>
                        <p style={commentTextStyle}>{comment.comment}</p>
                    </div>
                ))}

            </div>
            {isPopupOpen && (
                <div style={popupStyles.overlay}>
                    <div style={popupStyles.popupContent}>
                        <p>Are you sure you want to delete this comment?</p>
                        <button
                            style={{
                                ...popupStyles.button,
                                ...popupStyles.confirmButton,
                            }}
                            onClick={() => deleteComment(commentToDelete)}
                        >
                            Yes
                        </button>
                        <button
                            style={{
                                ...popupStyles.button,
                                ...popupStyles.cancelButton,
                            }}
                            onClick={() => {
                                setIsPopupOpen(false);
                                setCommentToDelete(null);
                            }}
                        >
                            No
                        </button>
                    </div>
                </div>
            )}

        </div>

    );
}

export default Comments;