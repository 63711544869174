import { USDollar } from "utils/format"
import { useContext } from "react"
import { GlobalContext } from 'GlobalContext';
import { InvestorsTableViewSubTable } from "./InvestorsTableViewSubTable";

export function InvestorsTableViewTable(props) {
    const { allPositions, accountIdToInvestorsAll } = props
    const { setManageRecordId, setManageRecordTab } = useContext(GlobalContext)
    // console.log(allPositions)
    // console.log(accountIdToInvestorsAll)

    var investorIds = new Set()
    for (const accountId of Object.keys(accountIdToInvestorsAll)) {
        for (const investor of accountIdToInvestorsAll[accountId]) {
            if (investor.investor_id) {
                investorIds.add(investor.investor_id)
            }
        }
    }

    const tableWidth = '1250px'
    const center = {
        display: "flex",
        alignItems: "center",
        placeContent: "center",
        textAlign: "center"
    }
    return <> {allPositions && <div style={{ width: "100%", overflow: "auto" }}>
        <div>{`Investor count: ${investorIds.size}, account count: ${Object.keys(accountIdToInvestorsAll).length}`}</div>

        <div style={{ width: tableWidth }}>
            <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid black" }}>
                <div style={{ width: "150px", ...center }}>Investors</div>
                <div style={{ width: "150px", ...center }}>Accounts</div>
                <div style={{ width: "150px", ...center }}>Funds</div>
                <div style={{ width: "150px", ...center }}>Commitment Number</div>
                <div style={{ width: "150px", ...center }}>Equity Balance</div>
                <div style={{ width: "150px", ...center }}>Distribute Interest</div>
                <div style={{ width: "150px", ...center }}>Principal Return</div>
                <div style={{ width: "150px", ...center }}>Capital In Date</div>
            </div>
            {Object.keys(accountIdToInvestorsAll)
                .map((account_id, id) => <InvestorsTableViewSubTable key={id} account_id={account_id} investors={accountIdToInvestorsAll[account_id]} positions={allPositions
                    .filter(position => position.account_id === parseInt(account_id))} />)}
        </div>

    </div>
    }
    </>
}