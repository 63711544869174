import React, { createContext, useState } from 'react';

// Create the context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
    const [globalEmail, setGlobalEmail] = useState("");
    const [globalId, setGlobalId] = useState(0) // user id of logged in user
    const [manageRecordTab, setManageRecordTab] = useState("Investors")
    const [manageRecordId, setManageRecordId] = useState(0)
    const [investorIdToView, setInvestorIdToView] = useState(-1) // investor id that is currently displaying
    const [accessToken, setAccessToken] = useState("")

    return (
        <GlobalContext.Provider value={{
            globalEmail,
            setGlobalEmail,
            globalId,
            setGlobalId,
            manageRecordTab,
            setManageRecordTab,
            manageRecordId,
            setManageRecordId,
            investorIdToView,
            setInvestorIdToView,
            accessToken,
            setAccessToken
        }}>
            {children}
        </GlobalContext.Provider>
    );
};
