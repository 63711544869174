import { useEffect, useState, useRef, useContext } from "react"
import api from "api"
import { get } from "utils/fetch";
import { message, Spin, Input, Switch } from "antd";
import { InvestorInsertion } from "pages/RecordInsertion/InvestorInsertion";
import { GlobalContext } from 'GlobalContext';
import { InvestorTableView } from "pages/Tables/InvestorTableView";
import { isAdmin } from "utils/util";
import * as XLSX from 'xlsx';
import { USDollar } from "utils/format";
import { saveAs } from 'file-saver';

export function InvestorManager() {
    const [messageApi, contextHolder] = message.useMessage();
    const { setManageRecordId, manageRecordId, accessToken, globalId } = useContext(GlobalContext)
    const fileInputRef = useRef(null);  // Reference to the file input element
    const [loading, setLoading] = useState(true)
    const [investors, setInvestors] = useState([])
    const [investorId, setInvestorId] = useState(0) // 0: main page
    const [filteredText, setFilteredText] = useState("")
    // const [accounts, setAccounts] = useState([])
    const [file, setFile] = useState(null);
    const [fileContent, setFileContent] = useState(null);
    const [fileSubmitButtonText, setFileSubmitButtonText] = useState("Submit")
    const [fileSubmitButtonWidth, setFileSubmitButtonWidth] = useState("70px")
    // list view or table view for display
    const [currentView, setCurrentView] = useState("List View")
    const [allPositions, setAllPositions] = useState([])
    const [accountIdToInvestorsAll, setAccountIdToInvestorsAll] = useState({})

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];

        // Check if the selected file is a CSV file
        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
            readFile(selectedFile);
        } else {
            alert("Please select a valid CSV file.");
            e.target.value = null;  // Reset the input if it's not a CSV file
        }
    };

    const readFile = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            validateCSV(content);
        };
        reader.readAsText(file);
    };

    const validateCSV = (content) => {
        const rows = content.split('\n');

        // Example validation: Check that there are at least two rows (header + 1 data row)
        if (rows.length < 2) {
            fileInputRef.current.value = '';
            alert('CSV file must contain at least one row of data.');
            setFile(null);
            return;
        }

        // Example: Validate CSV headers (assuming the first row contains headers)
        const headers = rows[0].split(/[,\t\r]+/);
        // console.log(headers)
        const expectedHeaders = ['first name', 'last name', 'email', 'phone', 'date', 'event']; // Replace with actual expected headers
        const missingHeaders = expectedHeaders
            .filter(header => !headers.includes(header))
        const isHeaderValid = headers.every((header, index) => header.trim() === expectedHeaders[index]);
        // console.log(missingHeaders)

        if (missingHeaders.length > 0) {
            fileInputRef.current.value = '';
            alert(`Missing columns: ${missingHeaders.join(", ")}`);
            setFile(null);
            return;
        }

        // Optionally, set file content if validation passes
        setFileContent(content);
    };
    // console.log("globalId", globalId)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fileSubmitButtonText === "Submitting") {
            return
        }
        setSubmittingStatus()

        if (!file || !fileContent) {
            alert("Please select a valid file!");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(api.file.investorBulkUpload, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (response.ok) {
                console.log('File uploaded successfully');
            } else {
                console.error('File upload failed');
            }
        } catch (error) {
            console.error('Error during file upload:', error);
        }
        unsetSubmittingStatus()
    };

    function setSubmittingStatus() {
        setFileSubmitButtonText("Submitting")
        setFileSubmitButtonWidth("100px")
    }

    function unsetSubmittingStatus() {
        fetchInvestors()
        setFileSubmitButtonText("Submit")
        setFileSubmitButtonWidth("70px")
        setFile(null);
        fileInputRef.current.value = '';
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    // async function fetchAccounts() {
    //     await get(api.manager.accounts, setAccounts, (errors) =>
    //         errors.forEach(err => {
    //             displayErrorMessage(err.message);
    //         })
    //     )
    //     setLoading(false)
    // }

    async function fetchAllPositions() {
        await get(api.manager.allInvestorPositions, setAllPositions, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        if (isAdmin()) {
            setLoading(false)
        }
    }

    async function fetchAccountIdToInvestorsAll() {
        await get(api.manager.accountIdToInvestorsAll + `?sale_id=${globalId}`, setAccountIdToInvestorsAll, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchInvestors() {
        await get(api.manager.investors + `?sale_id=${globalId}`, setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    useEffect(() => {
        if (accessToken === null || accessToken === undefined || accessToken === "" || globalId === 0) return
        if (manageRecordId !== 0) {
            setLoading(false)
        }
        setFilteredText("")
        if (manageRecordId !== 0) return
        fetchAccountIdToInvestorsAll()
        fetchInvestors()
        fetchAllPositions()
        // fetchAccounts()
    }, [manageRecordId, accessToken, globalId])

    const downloadCSV = () => {
        fetch(api.manager.downloadInvestors, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.blob(); // Convert response to blob
                }
                throw new Error('Error while downloading CSV');
            })
            .then(blob => {
                // Create a link element to trigger download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'investors.csv'); // Specify file name
                document.body.appendChild(link);
                link.click(); // Simulate click to download
                link.remove(); // Remove link from DOM after downloading
            })
            .catch(error => {
                console.error('Error downloading the CSV file:', error);
            });
    };

    function handleCurrentViewChange(view) {
        if (view) {
            setCurrentView("Table View")
        } else {
            setCurrentView("List View")
        }
    }

    // export investor table view into excel
    function exportToExcel(data, fileName = "Investor_Report.xlsx") {
        try {
            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Convert data to worksheet with minimal formatting
            const worksheet = XLSX.utils.aoa_to_sheet(data, {
                cellDates: true, // Handle dates properly
                raw: false  // Use more basic cell formatting
            });

            // Set very basic column widths
            worksheet['!cols'] = Array(data[0].length).fill({ wch: 20 });

            // Set basic row heights
            worksheet['!rows'] = Array(data.length).fill({});

            // Append worksheet with minimal styling
            XLSX.utils.book_append_sheet(workbook, worksheet, "Investor Summary");

            // Write file with basic options
            const wopts = {
                bookType: 'xlsx',
                type: 'binary',
                cellStyles: false,  // Disable complex styling
                cellDates: true,
                bookSST: false     // Disable shared strings table
            };

            XLSX.writeFile(workbook, fileName, wopts);

        } catch (error) {
            console.error("Error generating Excel file:", error);
        }
    }

    function generateInvestoTableView() {
        var data = [
            ["Investors", "Accounts", "Funds", "Commitment Number", "Equity Balance", "Distribute Interest", "Principal Return", "Capital In Date"], // Headers
        ];
        for (const account_id of Object.keys(accountIdToInvestorsAll)) {
            const investors = accountIdToInvestorsAll[account_id]
            const investorText = investors.map(investor => {
                if (!investor.legal_name && !investor.email) {
                    return ""
                } else if (!investor.legal_name) {
                    return investor.email
                } else if (!investor.email) {
                    return investor.legal_name
                } else {
                    return `${investor.legal_name}\n${investor.email}`
                }
            }).join('\n')
            const positions = allPositions
                .filter(position => position.account_id === parseInt(account_id))
            for (const position of positions) {
                var curPositionRow = []
                curPositionRow.push(investorText)
                curPositionRow.push(investors[0].account_name)
                curPositionRow.push(position.name)
                curPositionRow.push(USDollar.format(position.amount))
                curPositionRow.push(USDollar.format(position.account_balance))
                curPositionRow.push(USDollar.format(position.interest_received))
                curPositionRow.push(USDollar.format(position.principal_returned))
                curPositionRow.push(position.funding_receipt_date.slice(0, 10))
                data.push(curPositionRow)
            }
        }
        return data
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && manageRecordId === 0 && <>
            <div style={{ width: "300px", marginTop: "30px", marginLeft: "20px" }}>
                <Input
                    onChange={e => setFilteredText(e.target.value.trim().toLowerCase())}
                    placeholder="Filter on name or email"
                />
            </div>
            <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                {isAdmin() && <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => setManageRecordId(-1)}>
                    Add
                </button>}

                {isAdmin() && <form onSubmit={handleSubmit}>
                    {/* <label> */}
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ width: "200px" }}
                    />
                    {/* </label> */}
                    <button type="submit" style={{
                        height: "30px",
                        width: fileSubmitButtonWidth,
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}>{fileSubmitButtonText}</button>
                </form>}

                {currentView.toLowerCase() === "list view" && <button style={{
                    height: "30px",
                    width: "130px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={downloadCSV}>Export Investors</button>}

                {currentView.toLowerCase() === "table view" && <button style={{
                    height: "30px",
                    width: "160px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={() => exportToExcel(generateInvestoTableView())}>Export Investor Table</button>}
            </div>

            <div style={{ margin: "20px" }}>
                <Switch
                    // className="investment-status-switch"
                    checkedChildren="Table View"
                    unCheckedChildren="List View"
                    onChange={handleCurrentViewChange}
                    checked={currentView.toLowerCase() === "table view"}
                />
            </div>

            {currentView.toLowerCase() === "list view" && investors
                .filter(investor =>
                    investor.legal_name.toLowerCase().includes(filteredText) || investor.email.toLowerCase().includes(filteredText))
                .map((investor, idx) => {
                    return <div key={idx} style={{
                        fontSize: "20px",
                        margin: "20px",
                        width: "fit-content",
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                        onClick={() => setManageRecordId(investor.id)}
                    >
                        {`${investor.legal_name} (${investor.email})`}
                    </div>
                })}

            {currentView.toLowerCase() === "table view" && <InvestorTableView allPositions={allPositions} accountIdToInvestorsAll={accountIdToInvestorsAll} />}
        </>
        }
        {!loading && manageRecordId > 0 && <>
            <InvestorInsertion
            // investor={investors.filter(investor => investor.id === investorId)[0]} 
            // setInvestorId={setInvestorId} 
            // accounts={accounts} 
            />
        </>}
        {!loading && manageRecordId < 0 && <>
            <InvestorInsertion
            // investor={null} 
            // setInvestorId={setInvestorId} 
            // accounts={accounts} 
            />
        </>}
    </>
}