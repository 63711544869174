import { useEffect, useState, useContext } from "react"
import backArrow from "../../assets/img/backArrow.png"
import api from "api"
import { Select, Space, Spin, message } from "antd"
import { isPhoneSize, investorStructToName, isAdmin } from "utils/util"
import { get } from "utils/fetch"
import { GlobalContext } from "GlobalContext";

export function CustomerManager(props) {
    const { setView } = props
    const { accessToken, manageRecordId } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [investors, setInvestors] = useState([])
    const [customerIdSelected, setCustomerIdSelected] = useState([])
    const [customersDisplay, setCustomersDisplay] = useState([])
    const [investorName2id, setInvestorName2id] = useState({})
    const [customersBySale, setCustomersBySale] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var investorName2id = {}
        for (const investor of investors) {
            investorName2id[investorStructToName(investor)] = investor.id
        }
        setInvestorName2id(investorName2id)
    }, [investors])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const entryStyle = isPhoneSize() ? {
        margin: "20px",
        fontSize: '20px'
    } : {
        margin: "20px",
        fontSize: '20px',
    }

    async function fetchInvestors() {
        await get(api.manager.investors, setInvestors, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchCustomers() {
        await get(api.manager.customersBySale(manageRecordId), setCustomersBySale, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    useEffect(() => {
        setCustomerIdSelected(customersBySale.map(customer => customer.id))
        setCustomersDisplay(customersBySale.map(customer => investorStructToName(customer)))
    }, [customersBySale])

    // console.log(customersBySale)
    // console.log("customerIdSelected", customerIdSelected)

    function handleCustomerChange(customerNames) {
        setCustomerIdSelected(customerNames.map(customerName => investorName2id[customerName]))
        setCustomersDisplay(customerNames)
    }

    useEffect(() => {
        if (isAdmin()) {
            fetchInvestors()
        }
        // already customer of cur sale
        fetchCustomers()
    }, [])

    async function saveCustomers() {
        const customerIdsHeld = customersBySale.map(customer => customer.id)

        if (customerIdsHeld.sort().toString() === customerIdSelected.sort().toString()) {
            return
        }

        setLoading(true)
        // newly added account idx (selected but not previously held)
        const addedCustomerIds = customerIdSelected.length === 0 ? [] : customerIdSelected.filter(id => !customerIdsHeld.includes(id));
        // removed property idx (previously invested but not selected)
        const removedCustomerIds = customerIdsHeld.length === 0 ? [] : customerIdsHeld.filter(id => !customerIdSelected.includes(id))
        // console.log(accountIdsHeld, addedAccountIds, removedAccountIds)
        const option = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                addedCustomerIds,
                removedCustomerIds
            }),
        }
        await fetch(api.manager.sale(manageRecordId), option);
        fetchCustomers()
    }

    return <>
        <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
            <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                src={backArrow}
                onClick={() => setView('investor')}
            ></img>
        </div>

        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}

        {!loading && <>
            <div style={entryStyle}>
                <><div style={isPhoneSize() || !isAdmin() ? {
                    display: "flex", flexDirection: "column"
                } : {
                    display: "flex", flexDirection: "row"
                }}>
                    <div>Customers: </div>
                    {isAdmin() ? <span>
                        <Select
                            mode="multiple"
                            style={isPhoneSize() ? {
                                width: '300px'
                            } : {
                                width: '300px', marginLeft: "5px"
                            }}
                            placeholder="select customers"
                            value={customersDisplay}
                            onChange={(customers) => {
                                handleCustomerChange(customers)
                            }}
                            options={
                                investors.map(investor => ({
                                    label: investorStructToName(investor),
                                    value: investorStructToName(investor),
                                    desc: investorStructToName(investor)
                                }))
                            }
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span> : <div>
                        {customersBySale.map((customer, id) => <div key={id}>
                            {investorStructToName(customer)}
                        </div>)}
                    </div>}
                </div></>

            </div>

            {isAdmin() && <button style={{
                height: "30px",
                width: "50px",
                borderRadius: "30px",
                background: "rgb(17 32 86)",
                color: "white",
                cursor: "pointer",
            }}
                onClick={saveCustomers}
            >
                Save
            </button>}
        </>}
    </>
}