import { Divider } from 'antd';
import { getFileIcon, convertAndFormatDatetime, isPhoneSize } from 'utils/util';
import "./Documents.css"

export function DocumentSubView(props) {
    const { titleName, files } = props

    const dividerStyle = {
        margin: "0px"
    }

    const categoryStyle = {
        fontSize: "16px",
        marginLeft: "20px",
        marginTop: "5px",
        marginBottom: "5px"
    }

    var documentCategories = new Set()
    for (const file of files) {
        if (!file.type) continue
        documentCategories.add(file.type)
    }

    return <>
        <div>
            <div style={{ margin: "10px 0" }}>
                {titleName}
            </div>
            <Divider style={dividerStyle} />
        </div>
        {Array.from(documentCategories)
            .map((category, cid) => <div key={cid}>
                <div style={categoryStyle}>
                    {category}
                </div>
                <Divider style={dividerStyle} />
                {/* get file of category category */}
                {files.filter(file => file.type && file.type === category)
                    .map((file, id) => {
                        return <div key={id}>
                            <div key={id} style={isPhoneSize() ? {
                                display: "flex", alignItems: "center", fontSize: "15px",
                            } : {
                                display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px"
                            }}>
                                <a key={id} className="hoverable-link" style={{
                                    alignItems: "center",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "10px",
                                }} href={file.url} target="_blank" rel="noreferrer noopener">
                                    {/* <div className="hoverable-link" style={{ width: "100%", margin: "5px 0" }}> */}
                                    <div style={{ fontSize: "20px" }}>
                                        {getFileIcon(file.url)}
                                        {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                    </div>
                                    <div style={{ marginLeft: "10px", color: "black" }}>
                                        {file.url.substring(file.url.lastIndexOf('/') + 1)}
                                    </div>

                                    {/* <div style={{ marginLeft: "auto", color: "black", minWidth: "150px", paddingLeft: "10px" }}>
                                        {file.type}
                                    </div> */}

                                    {!isPhoneSize() && <div style={{ marginLeft: "auto", color: "black", minWidth: "100px" }}>
                                        {`- ${convertAndFormatDatetime(file.timestamp)}`}
                                    </div>}
                                    {/* </div> */}
                                </a>

                            </div>
                            <Divider style={dividerStyle} />
                        </div>
                    })}
            </div>)}
        {files.filter(file => !file.type).length > 0 &&
            <div>
                <div style={categoryStyle}>
                    {"Others"}
                </div>
                <Divider style={dividerStyle} />
                {/* get file of category category */}
                {files.filter(file => !file.type)
                    .map((file, id) => {
                        return <div key={id}>
                            <div key={id} style={isPhoneSize() ? {
                                display: "flex", alignItems: "center", fontSize: "15px",
                            } : {
                                display: "flex", alignItems: "center", fontSize: "15px", paddingLeft: "0px"
                            }}>
                                <a key={id} className="hoverable-link" style={{
                                    alignItems: "center",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    padding: "10px",
                                }} href={file.url} target="_blank" rel="noreferrer noopener">
                                    {/* <div className="hoverable-link" style={{ width: "100%", margin: "5px 0" }}> */}
                                    <div style={{ fontSize: "20px" }}>
                                        {getFileIcon(file.url)}
                                        {/* <img src={PdfIcon} style={{ width: "15px" }}></img> */}
                                    </div>
                                    <div style={{ marginLeft: "10px", color: "black" }}>
                                        {file.url.substring(file.url.lastIndexOf('/') + 1)}
                                    </div>

                                    {/* <div style={{ marginLeft: "auto", color: "black", minWidth: "150px", paddingLeft: "10px" }}>
                                        {file.type}
                                    </div> */}

                                    {!isPhoneSize() && <div style={{ marginLeft: "auto", color: "black", minWidth: "100px" }}>
                                        {`- ${convertAndFormatDatetime(file.timestamp)}`}
                                    </div>}
                                    {/* </div> */}
                                </a>

                            </div>
                            <Divider style={dividerStyle} />
                        </div>
                    })}
            </div>}
    </>
}