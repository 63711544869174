import backArrow from "../../assets/img/backArrow.png"
import { useState, useEffect, useContext } from "react"
import api from "api"
import { get } from "utils/fetch"
import { Select, Space, message, Spin } from "antd"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { propertyToAddress } from "utils/util"
import { isPhoneSize, isAdmin } from "utils/util"
import { USDollar } from "utils/format"
import { GlobalContext } from 'GlobalContext';

export function AccountInsertion(props) {
    const { setAccountId } = props
    const { setManageRecordId, manageRecordId, setManageRecordTab, accessToken, globalId } = useContext(GlobalContext)

    const [messageApi, contextHolder] = message.useMessage();
    const [dirty, setDirty] = useState(false)
    const [saving, setSaving] = useState(false)
    const [account, setAccount] = useState(null)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [routingNumber, setRoutingNumber] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [accountName, setAccountName] = useState("")
    const [bankAddress, setBankAddress] = useState("")
    const [bankName, setBankName] = useState("")
    const [fundsByAccount, setFundsByAccount] = useState([]) // funds that current account is investing
    const [investorsByAccount, setInvestorsByAccount] = useState([]) // investors that is holding the account
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    const [loading, setLoading] = useState(true)
    const [companiesByAccount, setCompaniesByAccount] = useState([])
    const [propertiesByAccount, setPropertiesByAccount] = useState([])
    const [positionsByAccount, setPositionsByAccount] = useState([])
    const [EIN, setEIN] = useState("")
    const [editMode, setEditMode] = useState(manageRecordId === -1 ? true : false)

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchFundsByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.fundsByAccount(id), setFundsByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchCompaniesByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.companiesByAccount(id), setCompaniesByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchPropertiesByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.propertiesByAccount(id), setPropertiesByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.account(id), setAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchInvestorByAccount(id) {
        // retrieve accounts investing the current fund
        await get(api.manager.investorsByAccount(id) + `?sale_id=${globalId}`, setInvestorsByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchPositionsByAccount(id) {
        await get(api.manager.positionsByAccount(id), setPositionsByAccount, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    useEffect(() => {
        if (manageRecordId <= 0) {
            setLoading(false)
        }
        // if account is not null
        if (account !== null) {
            // retrieve funds associate with it
            fetchPositionsByAccount(account.id)
            fetchFundsByAccount(account.id)
            fetchCompaniesByAccount(account.id)
            fetchPropertiesByAccount(account.id)
            fetchInvestorByAccount(account.id)
            restore()
        }
    }, [account])

    function restore() {
        setRoutingNumber(account === null || account.routing_number === null ? "" : account.routing_number)
        setAccountNumber(account === null || account.account_number === null ? "" : account.account_number)
        setAccountName(account === null || account.account_name === null ? "" : account.account_name)
        setBankAddress(account === null || account.bank_address === null ? "" : account.bank_address)
        setBankName(account === null || account.bank_name === null ? "" : account.bank_name)
        setEIN(account === null || account.ein === null ? "" : account.ein)
    }
    useEffect(() => {
        if (manageRecordId > 0) {
            fetchAccount(manageRecordId)
        }
    }, [manageRecordId])

    function canSubmit() {
        var invalidFields = []
        if (accountName.trim().length === 0) {
            invalidFields.push("Please fill in account name.")
        }
        if (bankName.trim().length === 0) {
            invalidFields.push("Please fill in bank name.")
        }
        if (accountNumber.trim().length === 0) {
            invalidFields.push("Please fill in account number.")
        }
        if (routingNumber.trim().length === 0) {
            invalidFields.push("Please fill in routing number.")
        }
        if (bankAddress.trim().length === 0) {
            invalidFields.push("Please fill in bank address.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveAccount() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            // setManageRecordId(0)
            return
        }
        setSaving(true)
        setAccountName(accountName.trim())
        setBankName(bankName.trim())
        setAccountNumber(accountNumber.trim())
        setRoutingNumber(routingNumber.trim())
        setBankAddress(bankAddress.trim())
        setEIN(EIN.trim())
        const body = JSON.stringify({
            accountName,
            bankName,
            accountNumber,
            routingNumber,
            bankAddress,
            EIN
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        var returnId = 0
        setLoading(true)
        if (account === null) {
            const res = await fetch(api.manager.accounts, options)
            returnId = await res.json()
        } else {
            const res = await fetch(api.manager.account(account.id), options);
            returnId = await res.json()
        }
        if (returnId === 0) {
            setLoading(false)
            setManageRecordId(0)
            return
        }
        setManageRecordId(returnId)
        fetchAccount(returnId)
        setSaving(false)
        setEditMode(false)
    }

    function deleteAccount() {
        if (investorsByAccount.length > 0 || fundsByAccount.length > 0) {
            setCreationErrorMessages([
                ...investorsByAccount
                    .map(investor => {
                        return `Current account is being held by ${investor.legal_name}.`
                    }),
                ...fundsByAccount
                    .map(fund => {
                        return `Current account is investing ${fund.name}.`
                    }),
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            // body
        }
        const result = await fetch(api.manager.account(account.id), options);
        const data = await result.json()
        setManageRecordId(0)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <div>

            {account !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Account: ${account.account_name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setManageRecordId(0)}
                ></img>
            </div>

            {isAdmin() && manageRecordId > 0 && <div onClick={e => {
                if (editMode) {
                    restore()
                }
                setEditMode(!editMode)
            }}
                style={{
                    width: "fit-content",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "20px"
                }}>
                {editMode ? "Restore" : "Edit"}
            </div>}

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Account Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={accountName}
                    onInput={(e) => {
                        setAccountName(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>Account Name: {accountName}</div>}
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Bank Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={bankName}
                    onInput={(e) => {
                        setBankName(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>Bank Name: {bankName}</div>}
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Account Number<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={accountNumber}
                    onInput={(e) => {
                        setAccountNumber(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>Account Number: {accountNumber}</div>}
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Routing Number<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={routingNumber}
                    onInput={(e) => {
                        setRoutingNumber(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>Routing Number: {routingNumber}</div>}
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Bank Address<span style={{ color: "red" }}>*</span>:</div> <input style={{ ...inputStyle, "width": "350px", "fontSize": "13px" }}
                    value={bankAddress}
                    onInput={(e) => {
                        setBankAddress(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>Bank Address: {bankAddress}</div>}
            </div>

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>EIN:</div> <input style={inputStyle}
                    value={EIN}
                    onInput={(e) => {
                        setEIN(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && <div>EIN: {EIN}</div>}
            </div>

            {1 === 2 && <div style={entryStyle}>
                Positions:
                {/* head */}
                <div style={{ overflowX: "scroll", scrollbarWidth: "none" }}>
                    <div style={{ width: "1050px" }}>
                        <div style={{ display: "flex", fontSize: "17px" }}>
                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Fund
                            </div>

                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Commitment Number
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Equity Balance
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Capital in Date
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Distribute Interest
                            </div>

                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                Principal Return
                            </div>
                        </div>
                        {positionsByAccount.length > 0 && <div style={{ border: "1px solid black", width: "1050px" }}>
                            {
                                positionsByAccount
                                    // .sort((a, b) => {
                                    //     if (a.legal_name < b.legal_name) {
                                    //         return -1
                                    //     }
                                    //     return 1
                                    // })
                                    .map((position, idx) => {
                                        return <div key={idx} style={{ display: "flex", flexDirection: "row", borderTop: idx > 0 ? "1px solid black" : "" }}>
                                            <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center", flexDirection: 'column' }}>
                                                {position.fund_name}
                                            </div>

                                            <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.amount)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.account_balance)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {position.funding_receipt_date.slice(0, 10)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.interest_received)}
                                            </div>

                                            <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                                {USDollar.format(position.principal_returned)}
                                            </div>
                                        </div>
                                    }
                                    )
                            }

                            <div style={{ display: "flex", borderTop: "1px solid black" }}>
                                <div style={{ width: "270px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    Summary
                                </div>

                                <div style={{ width: "180px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.amount
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.account_balance
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>

                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.interest_received
                                        }, 0)
                                    )}
                                </div>

                                <div style={{ width: "150px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {USDollar.format(
                                        positionsByAccount.reduce((acc, cur) => {
                                            return acc + cur.principal_returned
                                        }, 0)
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>}

            <div style={entryStyle}>
                Investors:
                {investorsByAccount
                    .sort((a, b) => {
                        if (a.legal_name < b.legal_name) {
                            return -1
                        }
                        return 1
                    })
                    .map((investor, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(investor.id)
                                    setManageRecordTab("Investors")
                                }}>
                                {investor.legal_name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Entities:
                {fundsByAccount
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((fund, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(fund.id)
                                    setManageRecordTab("Entities/Funds")
                                }}>
                                {fund.name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Holding Companies:
                {companiesByAccount
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((company, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(company.id)
                                    setManageRecordTab("Holding Companies")
                                }}>
                                {company.name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Assets:
                {propertiesByAccount
                    .sort((a, b) => {
                        if (propertyToAddress(a) < propertyToAddress(b)) {
                            return -1
                        }
                        return 1
                    })
                    .map((property, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(property.id)
                                    setManageRecordTab("Assets")
                                }}>
                                {propertyToAddress(property)}
                            </div>
                        </div>
                    })}
            </div>

            {isAdmin() && !saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveAccount}>
                    Save
                </button>

                {account !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteAccount}
                >
                    Delete
                </button>}
            </div>}

        </div>}
    </>
}