import { USDollar } from "utils/format"
import { GlobalContext } from 'GlobalContext';
import { useContext } from "react"

export function InvestorsTableViewSubTable(props) {
    const { account_id, investors, positions } = props
    const { setManageRecordId, setManageRecordTab } = useContext(GlobalContext)

    const center = {
        display: "flex",
        alignItems: "center",
        placeContent: "center",
        textAlign: "center"
    }

    return <>
        <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid black" }}>
            {/* investor holding account id = account_id */}
            <div style={{ display: "flex", flexDirection: "column", width: "150px", ...center }}>
                {investors
                    .map((investor, iid) => <div key={iid} style={{}}>
                        <div style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                            onClick={e => {
                                setManageRecordId(investor.investor_id)
                                setManageRecordTab("Investors")
                            }}>
                            {investor.legal_name}
                        </div>

                        <div>
                            {investor.email}
                        </div>
                    </div>)}
            </div>

            <div style={{
                width: "150px",
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                ...center
            }}
                onClick={e => {
                    setManageRecordId(account_id)
                    setManageRecordTab("Accounts")
                }}>
                {investors[0].account_name}
            </div>

            {/* investment made by account id = account_id */}
            <div style={{ display: "flex", flexDirection: "column" }}>
                {positions
                    .map((position, pid) => <div key={pid} style={{
                        display: "flex",
                        flexDirection: "row",
                        borderTop: pid === 0 ? "" : "1px solid black",
                        flexGrow: 1,
                        ...center
                    }}>
                        <div style={{
                            width: "150px",
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                            ...center
                        }}
                            onClick={e => {
                                setManageRecordId(position.fund_id)
                                setManageRecordTab("Entities/Funds")
                            }}>
                            {position.name}
                        </div>

                        <div style={{ width: "150px", ...center }}>
                            {USDollar.format(position.amount)}
                        </div>

                        <div style={{ width: "150px", ...center }}>
                            {USDollar.format(position.account_balance)}
                        </div>

                        <div style={{ width: "150px", ...center }}>
                            {USDollar.format(position.interest_received)}
                        </div>

                        <div style={{ width: "150px", ...center }}>
                            {USDollar.format(position.principal_returned)}
                        </div>

                        <div style={{ width: "150px", ...center }}>
                            {position.funding_receipt_date.slice(0, 10)}
                        </div>

                        <div style={{ width: "50px", display: "flex", placeContent: "center", alignItems: "center", textAlign: "center" }}>
                            <button
                                style={{ background: "rgb(17 32 86)", color: "white", height: "30px", borderRadius: "10px", cursor: "pointer" }}
                                onClick={() => {
                                    setManageRecordId(position.investment_id)
                                    setManageRecordTab("Positions")
                                }}
                            >
                                Detail
                            </button>
                        </div>
                    </div>)}
            </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", borderBottom: "1px solid black", height: "30px", fontWeight: "bold" }}>
            <div style={{ width: "150px", ...center }}>Summary</div>
            <div style={{ width: "300px", alignContent: "center" }}></div>
            <div style={{ width: "150px", ...center }}>{USDollar.format(positions.reduce((acc, cur) => {
                return acc + cur.amount
            }, 0))}</div>

            <div style={{ width: "150px", ...center }}>{USDollar.format(positions.reduce((acc, cur) => {
                return acc + cur.account_balance
            }, 0))}</div>

            <div style={{ width: "150px", ...center }}>{USDollar.format(positions.reduce((acc, cur) => {
                return acc + cur.interest_received
            }, 0))}</div>

            <div style={{ width: "150px", ...center }}>{USDollar.format(positions.reduce((acc, cur) => {
                return acc + cur.principal_returned
            }, 0))}</div>
        </div>
    </>
}