import backArrow from "../../assets/img/backArrow.png"
import { useEffect, useState, useContext } from "react"
import { Select, Space, message, Spin } from "antd"
import { propertyToAddress } from "utils/util"
import api from "api"
import { get } from "utils/fetch"
import { DeleteConfirmation } from "components/DeleteConfirmation"
import { isPhoneSize, isAdmin } from "utils/util"
import { GlobalContext } from "GlobalContext";

export function HoldingCompanyInsertion(props) {

    // const { holdingCompany, setHoldingCompanyId, properties } = props

    const { setManageRecordId, manageRecordId, setManageRecordTab, accessToken, globalId } = useContext(GlobalContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [dirty, setDirty] = useState(false)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [saving, setSaving] = useState(false)
    const [propertiesInvested, setPropertyInvested] = useState([]) // properties invested before opening this page
    const [name, setName] = useState("")
    const [propertyIdxSelected, setPropertyIdxSelected] = useState([])
    // funds that are investing the holding company
    const [fundsByHoldingCompay, setFundsByHoldingCompay] = useState([])
    const [showDeletionBox, setShowDeletionBox] = useState(false)
    // property address -> percentage (how much percent of the property is holding company investing)
    const [investingPercent, setInvestingPercent] = useState({})
    const [investorsByCompany, setInvestorsByCompany] = useState([])
    const [accountsByCompany, setAccountsByCompany] = useState([])
    const [properties, setProperties] = useState([])
    const [holdingCompany, setHoldingCompany] = useState(null)
    const [propertyDisplay, setPropertyDisplay] = useState([]) // property display in dropdown menu
    const [editMode, setEditMode] = useState(manageRecordId === -1 ? true : false)

    useEffect(() => {
        const propertyIdsInvested = propertiesInvested.map((property, idx) => property.id)
        // console.log(propertyIdsInvested)
        setInvestingPercent(
            propertyIdxSelected.reduce((acc, cur) => {
                if (cur in investingPercent) {
                    acc[cur] = investingPercent[cur]
                } else if (propertyIdsInvested.includes(cur)) {
                    acc[cur] = propertiesInvested.filter(property => property.id === cur)[0].share_of_property === null ? 0 : propertiesInvested.filter(property => property.id === cur)[0].share_of_property
                } else {
                    acc[cur] = 0
                }
                return acc
            }, {})
        )
    }, [propertyIdxSelected])

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    async function fetchPropertiesInvested(id) {
        await get(api.manager.propertiesByHoldingCompany(id), setPropertyInvested, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchFunds(id) {
        // retrieve funds associate with holding company
        await get(api.manager.fundByHoldingCompany(id) + `?sale_id=${globalId}`, setFundsByHoldingCompay, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchInvestorsByCompany(id) {
        // retrieve funds associate with holding company
        await get(api.manager.investorsByCompany(id), setInvestorsByCompany, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    async function fetchAccountsByCompany(id) {
        // retrieve funds associate with holding company
        await get(api.manager.accountsByCompany(id), setAccountsByCompany, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        // setLoading(false)
    }

    // useEffect(() => {
    //     // if holdingCompany is not null
    //     if (holdingCompany !== null) {
    //         // retrieve funds associate with it
    //         fetchFunds(holdingCompany.id)
    //     }
    // }, [])

    // console.log(fundsByHoldingCompay)

    useEffect(() => {
        setPropertyIdxSelected(propertiesInvested.map(property => property.id))
        setPropertyDisplay(propertiesInvested.map(property => propertyToAddress(property)))
    }, [propertiesInvested])

    useEffect(() => {
        if (manageRecordId < 0) {
            setLoading(false)
            return
        }
        if (holdingCompany === null) {
            return
        }
        fetchInvestorsByCompany(holdingCompany.id)
        fetchAccountsByCompany(holdingCompany.id)
        fetchPropertiesInvested(holdingCompany.id)
        fetchFunds(holdingCompany.id)
        restore()
    }, [holdingCompany])

    function restore() {
        setName(holdingCompany === null || holdingCompany.name === null ? "" : holdingCompany.name)
    }

    async function fetchProperties() {
        await get(api.manager.properties, setProperties, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchHoldingCompany(id) {
        await get(api.manager.holdingCompany(id), setHoldingCompany, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    useEffect(() => {
        fetchProperties()
        if (manageRecordId > 0) {
            fetchHoldingCompany(manageRecordId)
        }
    }, [])

    var address2idx = {}
    for (const property of properties) {
        const addressFull = propertyToAddress(property)
        address2idx[addressFull] = property.id
    }
    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    function canSubmit() {
        var invalidFields = []
        if (name.trim().length === 0) {
            invalidFields.push("Please fill name.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveProperty() {
        if (canSubmit() === false) {
            return
        }
        if (dirty === false) {
            // setManageRecordId(0)
            return
        }
        setSaving(true)
        setName(name.trim())

        for (const id of propertyIdxSelected) {
            //console.log("selected property idx", id)
        }
        for (const property of propertiesInvested) {
            //console.log("Invested property idx", property.id, "(" + propertyToAddress(property) + ")")
        }
        // id invested previously
        const propertyIdInvested = propertiesInvested.length === 0 ? [] : propertiesInvested.map(property => property.id)
        // newly added property idx (selected but not previously invested)
        const addedPropertyIdx = propertyIdxSelected.length === 0 ? [] : propertyIdxSelected.filter(id => !propertyIdInvested.includes(id));
        // removed property idx (previously invested but not selected)
        const removedPropertyIdx = propertyIdInvested.length === 0 ? [] : propertyIdInvested.filter(id => !propertyIdxSelected.includes(id))

        const keptPropertyIds = propertyIdInvested.length === 0 ? [] : propertyIdInvested.filter(id => propertyIdxSelected.includes(id))

        const body = JSON.stringify({
            name,
            addedPropertyIdx,
            removedPropertyIdx,
            keptPropertyIds,
            investingPercent,
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        var retId = 0
        setLoading(true)
        if (holdingCompany === null) {
            const response = await fetch(api.manager.holdingCompanies, options); // new record
            retId = await response.json()
        } else {
            await fetch(api.manager.holdingCompany(holdingCompany.id), options); // old record
            retId = manageRecordId
        }
        if (retId === 0) {
            setManageRecordId(0)
            setLoading(false)
            return
        }
        setManageRecordId(retId)
        fetchHoldingCompany(retId)
        setEditMode(false)
        setSaving(false)
    }
    const handleChange = (addresses) => {
        setDirty(true)
        setPropertyIdxSelected(addresses.map(address => address2idx[address]))
        setPropertyDisplay(addresses)
    };
    const selectionOptions = properties
        .map(property => {
            const addressFull = propertyToAddress(property)
            return {
                label: addressFull,
                value: addressFull,
                desc: addressFull
            }
        })

    function deleteCompany() {
        if (fundsByHoldingCompay.length > 0 || propertiesInvested.length > 0) {
            setCreationErrorMessages([
                ...fundsByHoldingCompay
                    .map(fund => {
                        return `Current company is being invested by ${fund.name}.`
                    }),
                ...propertiesInvested
                    .map(property => {
                        return `Current company is investing ${propertyToAddress(property)}.`
                    }),
            ])
            return
        }
        setShowDeletionBox(true)
    }

    // called by DeleteConfirmation component to delete current record
    async function deletionCallback() {
        const options = {
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            // body
        }
        const result = await fetch(api.manager.holdingCompany(holdingCompany.id), options);
        const data = await result.json()
        setManageRecordId(0)
    }
    // console.log(propertyIdxSelected)
    // console.log(properties)
    // console.log(propertiesInvested)
    // console.log(investingPercent)
    // console.log(fundsByHoldingCompay)
    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading && <div>

            {holdingCompany !== null && showDeletionBox &&
                <DeleteConfirmation
                    title={`Holding Company: ${holdingCompany.name}`}
                    setShowDeletionBox={setShowDeletionBox}
                    deletionCallback={deletionCallback}
                />
            }

            <div style={{ margin: "20px" }}>
                <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                    src={backArrow}
                    onClick={() => setManageRecordId(0)}
                ></img>
            </div>

            {isAdmin() && manageRecordId > 0 && <div onClick={e => {
                if (editMode) {
                    restore()
                }
                setEditMode(!editMode)
            }}
                style={{
                    width: "fit-content",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginLeft: "20px"
                }}>
                {editMode ? "Restore" : "Edit"}
            </div>}

            {creationErrorMessages.map((message, idx) => {
                return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
            })}

            <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                {editMode && <><div>Name<span style={{ color: "red" }}>*</span>:</div> <input style={inputStyle}
                    value={name}
                    onInput={(e) => {
                        setName(e.target.value)
                        setDirty(true)
                    }}></input></>}

                {!editMode && `Name: ${name}`}
            </div>

            <div style={entryStyle}>
                <div style={isPhoneSize() ? {
                    display: "flex", flexDirection: "column"
                } : {
                    display: "flex", flexDirection: "row"
                }}>
                    {editMode && <><div>Assets: </div>
                        <span>
                            <Select
                                mode="multiple"
                                style={isPhoneSize() ? {
                                    width: '300px'
                                } : {
                                    width: '300px', marginLeft: "5px"
                                }}
                                placeholder="select properties"
                                value={propertyDisplay}
                                onChange={handleChange}
                                options={selectionOptions}
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            {option.data.emoji}
                                        </span>
                                        {option.data.desc}
                                    </Space>
                                )}
                            />
                        </span></>}

                    {!editMode && <div >
                        Assets:
                        {propertiesInvested
                            .sort((a, b) => {
                                if (propertyToAddress(a) < propertyToAddress(b)) {
                                    return -1
                                }
                                return 1
                            })
                            .map((property, idx) => {
                                return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{
                                        // fontSize: "20px",
                                        // margin: "20px",
                                        width: "fit-content",
                                    }}
                                        onClick={() => {
                                            setManageRecordId(property.id)
                                            setManageRecordTab("Assets")
                                        }}>
                                        {propertyToAddress(property)}
                                    </div>
                                </div>
                            })}
                    </div>}
                </div>
            </div>

            <div style={entryStyle}>
                Investing Percent:
                {propertyIdxSelected.map((propertyId, idx) => {
                    return <div key={idx} style={isPhoneSize() ? {
                        display: "flex", flexDirection: "column"
                    } : {
                        display: "flex"
                    }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {propertyToAddress(properties.filter((property) => property.id === propertyId)[0])}:
                        </div>
                        {editMode && <><div>
                            <input style={{
                                outline: 'none',
                                border: "none",
                                borderBottom: "1px solid black",
                                background: 'transparent',
                                textAlign: "right",
                                width: "100px"
                            }}
                                value={investingPercent[propertyId] !== undefined ? investingPercent[propertyId] : ""}
                                onChange={e => {
                                    // Update state with the raw string input value, allowing "."
                                    const value = e.target.value;

                                    // Set the state with the updated value (as a string)
                                    setDirty(true);
                                    setInvestingPercent({ ...investingPercent, [propertyId]: value });
                                }}
                                onBlur={e => {
                                    // Convert to float when input loses focus
                                    const value = parseFloat(e.target.value) || 0; // Default to 0 if invalid
                                    setInvestingPercent({ ...investingPercent, [propertyId]: value });
                                }}
                            ></input>%
                        </div></>}

                        {!editMode && `${investingPercent[propertyId]}%`}
                    </div>
                })}
            </div>

            <div style={entryStyle}>
                Entities:
                {fundsByHoldingCompay
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        }
                        return 1
                    })
                    .map((fund, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(fund.id)
                                    setManageRecordTab("Entities/Funds")
                                }}>
                                {fund.name}
                            </div>
                        </div>
                    })}
            </div>

            <div style={entryStyle}>
                Assets:
                {propertiesInvested
                    .sort((a, b) => {
                        if (propertyToAddress(a) < propertyToAddress(b)) {
                            return -1
                        }
                        return 1
                    })
                    .map((property, idx) => {
                        return <div key={idx} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{
                                // fontSize: "20px",
                                // margin: "20px",
                                width: "fit-content",
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                                onClick={() => {
                                    setManageRecordId(property.id)
                                    setManageRecordTab("Assets")
                                }}>
                                {propertyToAddress(property)}
                            </div>
                        </div>
                    })}
            </div>

            {isAdmin() && !saving && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                <button style={{
                    height: "30px",
                    width: "50px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={saveProperty}>
                    Save
                </button>

                {holdingCompany !== null && <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "red",
                    color: "white",
                    cursor: "pointer",
                }}
                    onClick={deleteCompany}
                >
                    Delete
                </button>}
            </div>}
            {isAdmin() && saving && <div style={{ margin: "20px" }}>
                <button style={{
                    height: "30px",
                    width: "70px",
                    borderRadius: "30px",
                    background: "rgb(17 32 86)",
                    color: "white",
                    cursor: "pointer",
                }}>
                    Saving
                </button>
            </div>}
        </div>}
    </>
}