import api from 'api';
import { useEffect, useState, useContext } from 'react';
import { Col, Divider, Layout, Row, Spin, Table, message, Typography, Collapse, Tooltip, Select } from 'antd';
import OfferingTile from 'pages/Offerings/OfferingTile';
import { get } from 'utils/fetch';
import { OfferingDetailed } from 'pages/Offerings/OfferingDetailed';
import { useLocation } from 'react-router-dom';
import { OfferingGallery } from 'components/OfferingGallery';
import { CompanyInfo } from 'components/CompanyInfo';
import { AppHeader } from 'components/AppHeader';
import { isPhoneSize } from 'utils/util';
import { USDollar } from 'utils/format';
import { GlobalContext } from 'GlobalContext';

export function Holdings(props) {
    const { email, basicInfo, setEmail, setBasicInfo } = props

    const { accessToken } = useContext(GlobalContext)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    //console.log("Offering id:", id)

    const [loading, setLoading] = useState(true);
    const [funds, setFunds] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [fundTypeToView, setFundTypeToView] = useState("Both")
    const [activeInvestments, setActiveInvestments] = useState([])
    const [fundFraction, setFundFraction] = useState({})

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    // console.log(activeInvestments)

    useEffect(() => {
        var fundFraction = {}
        var totalAmount = 0
        for (const investment of activeInvestments) {
            const curFundName = investment["fund_name"]
            const amount = investment['account_balance']
            // console.log(amount)
            if (!fundFraction[curFundName]) {
                fundFraction[curFundName] = [0]
            }
            fundFraction[curFundName][0] += amount
            totalAmount += amount
        }
        for (const key of Object.keys(fundFraction)) {
            fundFraction[key].push(fundFraction[key][0] / totalAmount)
        }
        setFundFraction(fundFraction)
    }, [activeInvestments])

    async function fetchFunds(email) {
        await get(api.fund.investorList(email), setFunds, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
        setLoading(false);
    };

    async function fetchFundsId(id) {
        await get(api.fund.investorListId(id), setFunds, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
        setLoading(false);
    };

    async function fetchActiveInvestments(id) {
        await get(api.investor.getActiveInvestmentsTable(id), setActiveInvestments, (errors) => errors.forEach(err => {
            displayErrorMessage(err.message);
        }), accessToken);
    };

    async function fetchData(email, id) {
        fetchActiveInvestments(id)
        fetchFundsId(id);
        //console.log(funds)
    }
    // console.log(funds, Object.keys(fundFraction).length, funds.length)
    useEffect(() => {
        if (email === undefined || Object.keys(basicInfo).length === 0) return
        fetchData(email, basicInfo.id);
    }, [email, basicInfo]);

    if (id !== null) return <>
        {!isPhoneSize() && <AppHeader setBasicInfo={setBasicInfo} basicInfo={basicInfo} setEmail={setEmail} />}
        <OfferingDetailed id={id} isHolding={true} />
    </>
    // console.log()
    return <div style={isPhoneSize() ? {
        height: 'calc(100vh - 204px)',
        // overflowY: "scroll",
        // overflowX: "hidden",
        scrollbarWidth: "none",
    } : {
        width: "100%",
        textAlign: "center",
    }}>
        {contextHolder}
        <div style={isPhoneSize() ? {
            display: "flex", flexDirection: "column",
        } : {
            display: "flex"
        }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                {!isPhoneSize() && <AppHeader setBasicInfo={setBasicInfo} basicInfo={basicInfo} setEmail={setEmail} />}
                {loading && <div style={{ height: "100vh", width: "100%", marginTop: "30px", textAlign: "center" }}><Spin /></div>}
                {!loading && Object.keys(fundFraction).length === funds.length && <><div style={isPhoneSize() ? {

                } : {
                    width: "100%", textAlign: "left", height: 'calc(100vh - 144px)', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: "none"
                }}>
                    <Layout style={{ width: '100%', background: "transparent" }}>
                        <div style={{ display: "flex" }}>
                            <h1 style={{ width: '100%', paddingTop: "5px", textAlign: "left" }}>My Holdings</h1>
                            <Select
                                onChange={(e) => setFundTypeToView(e)}
                                placeholder="Filter Fund Type"
                                style={{ width: '200px' }}
                                optionFilterProp="children"
                                options={
                                    [{
                                        value: "Both",
                                        label: "Both",
                                    }, {
                                        value: "Debt",
                                        label: "Debt",
                                    }, {
                                        value: "Equity",
                                        label: "Equity",
                                    }]
                                }
                            >
                            </Select>
                        </div>
                        <Row gutter={[24, 24]} style={isPhoneSize() ? { justifyContent: "center" } : {}}>
                            {
                                funds
                                    .filter((fund) => {
                                        if (fundTypeToView === "Both") {
                                            return true
                                        } else {
                                            return fund.type.toLowerCase().includes(fundTypeToView.toLowerCase())
                                        }
                                    })
                                    .sort((a, b) => {
                                        if (a.name < b.name) return -1
                                        return 1
                                    })
                                    .map((f, idx) =>
                                        <Col key={idx}>
                                            <OfferingTile
                                                title={f.name}
                                                type={f.type}
                                                description={f.description}
                                                amount={USDollar.format(fundFraction[f.name][0])}
                                                fraction={`${(fundFraction[f.name][1] * 100).toFixed(2)}%`}
                                                link={`/holdings?id=${f.id}`}
                                                imgSrc={f.img_src ? f.img_src : 'https://static.wixstatic.com/media/1e11ac_c9300dd8e54c4e5397141c2246af71c6~mv2.png/v1/fill/w_948,h_1226,al_t,q_90,usm_0.66_1.00_0.01,enc_auto/1e11ac_c9300dd8e54c4e5397141c2246af71c6~mv2.png'}
                                            />
                                        </Col>
                                    )
                            }
                        </Row>

                    </Layout>
                    <div style={{ height: "50px" }}></div>
                </div>
                </>
                }
            </div>

            <div style={isPhoneSize() ? {
                textAlign: "left",
            } : {
                width: '380px',
                textAlign: "left",
                background: 'transparent',
                padding: 12,
                overflowY: 'scroll',
                overflowX: "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                // height: 'calc(100vh - 144px)',
                height: "100vh"
            }}>
                <Row gutter={[12, 16]}>

                </Row>

                <CompanyInfo />
            </div>

        </div>
    </div>
}
