import backArrow from "../../assets/img/backArrow.png"
import { USDollar } from "utils/format"
import { useState, useEffect, useContext } from "react"
import api from "api"
import { message, Spin, Select, Space, Calendar, theme, Switch } from "antd"
import { get } from "utils/fetch"
import { investorStructToDisplayedName, fundEntityToName, getTodayDateString, getTomorrowDateString } from "utils/util"
import { isPhoneSize, isAdmin, larkTextFieldToString, timeStampToString } from "utils/util"
import { GlobalContext } from 'GlobalContext';
import './RecordInsertion.css'

export function LarkInvestmentInsertion(props) {
    const { setManageRecordId, manageRecordId, setManageRecordTab, accessToken, globalId } = useContext(GlobalContext)
    const { setSource } = props
    const [investors, setInvestors] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true)
    const [creationErrorMessages, setCreationErrorMessages] = useState([])
    const [accountIdSelected, setAccountIdSelected] = useState(-1)
    const [fundIdSelected, setFundIdSelected] = useState(-1)
    const [amount, setAmount] = useState(0)
    const [method, setMethod] = useState("")
    const [type, setType] = useState("")
    const [frequency, setFrequency] = useState("")
    const [endDate, setEndDate] = useState("")
    const [interest, setInterest] = useState(0)
    const [overallInterest, setOverallInterest] = useState(0)
    const [startDate, setStartDate] = useState("")
    // whether end date of an existiing investment is changed
    const [endDateChanged, setEndDateChanged] = useState(false)
    const [account, setAccount] = useState("")
    const [investment, setInvestment] = useState(null)
    const [funds, setFunds] = useState([])
    const [subtype, setSubtype] = useState(null)
    const [editMode, setEditMode] = useState(manageRecordId === -1 ? true : false)
    // all accounts
    const [accounts, setAccounts] = useState([])
    const [publicFundName, setPublicFundName] = useState("")
    const [investorsByInvestment, setInvestorsByInvestment] = useState([])
    console.log(manageRecordId)

    const timezoneOffset = "T00:00:00-07:00"

    const { token } = theme.useToken();

    const switchStyle = {
        fontSize: '16px', // Adjust this to your preferred size
    };

    const wrapperStyle = {
        width: 300,
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };

    const entryStyle = {
        margin: "20px",
        fontSize: '20px'
    }

    const inputStyle = {
        border: "0",
        outline: "none",
        borderBottom: "1px solid black",
        fontSize: '20px',
        background: "transparent"
    }

    const displayErrorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    // console.log(investorsByInvestment)
    async function fetchAccounts() {
        await get(api.manager.accounts, setAccounts, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestment(id) {
        await get(api.manager.investment(id), setInvestment, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    async function fetchInvestmentDetailFromLark(requestNo) {
        await get(api.lark.investmentDetailFromLark(requestNo), setInvestment, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
        setLoading(false)
    }

    async function fetchFunds() {
        await get(api.manager.funds, setFunds, (errors) =>
            errors.forEach(err => {
                displayErrorMessage(err.message);
            }), accessToken
        )
    }

    // init, get investors and funds
    useEffect(() => {
        if (manageRecordId < 0) {
            setLoading(false)
            return
        }
        if (investment) {
            restore(investment)
        }
    }, [investment])

    function restore(investment) {
        setAmount(investment.fields.Amount)
        setStartDate(timeStampToString(investment.fields.起始日期))
        setEndDate(timeStampToString(investment.fields.结算日期))
        setOverallInterest(investment.fields['Rate(%)'])
    }

    useEffect(() => {
        fetchFunds()
        fetchAccounts()
        fetchInvestmentDetailFromLark(manageRecordId)
    }, [])

    function mapNameToId(entities, getKey) {
        var mapRes = {}
        for (const entity of entities) {
            mapRes[getKey(entity)] = entity.id
        }
        return mapRes
    }

    const investorNameToId = mapNameToId(investors, investorStructToDisplayedName)
    const fundNameToId = mapNameToId(funds, fundEntityToName)
    var accountName2id = {}
    for (const account of accounts) {
        accountName2id[account.account_name] = account.id
    }

    const subTypes = {
        debt: [
            "PPM-Notes",
            "Senior Loan",
            "2nd lien loan",
            "Unsecured loan"
        ],
        equity: [
            "PPM-Preferred Equity",
            "Preferred Equity",
            "Common Equity",
            "Debt Fund",
            "BRRRR"
        ]
    }

    // ----------------- account selecion -------------------
    const handleChangeAccount = (account) => {
        setAccountIdSelected(accountName2id[account])
    };

    const accountSelectionOptions = accounts
        .sort((a, b) => {
            if (a.account_name < b.account_name) {
                return -1
            }
            return 1
        })
        .map(account => {
            return {
                label: account.account_name,
                value: account.account_name,
                desc: account.account_name,
            }
        })

    // ----------------- fund selecion -------------------
    const handleChangeFund = (fund) => {
        setFundIdSelected(fundNameToId[fund])
    };

    const fundSelectionOptions = funds
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            return 1
        })
        .map(fund => {
            return {
                label: fund.name,
                value: fund.name,
                desc: fund.name,
            }
        })

    // ----------------- type selecion -------------------
    const handleChangeType = (type) => {
        setType(type)
        setSubtype(null)
    };

    const typeSelectionOptions = [{
        label: "Debt",
        value: "Debt",
        desc: "Debt",
    }, {
        label: "Equity",
        value: "Equity",
        desc: "Equity",
    }]


    // ----------------- method selecion -------------------

    const handleChangeMethod = (method) => {
        setMethod(method)
    };

    const methodSelectionOptions = [{
        label: "Reinvest",
        value: "Reinvest",
        desc: "Reinvest",
    }, {
        label: "Cashout",
        value: "Cashout",
        desc: "Cashout",
    }]
    //  ----------------- start date selection -------------------
    const onStartDateChange = (value, mode) => {
        //console.log(value.format('YYYY-MM-DD'), mode);
    };

    // ----------------- frequency selecion -------------------

    const handleChangeFrequency = (frequency) => {
        setFrequency(frequency)
    };

    const frequencySelectionOptions = [
        {
            label: "End of Project Payout",
            value: "End of Project Payout",
            desc: "End of Project Payout",
        }, {
            label: "Annually payout",
            value: "Annually payout",
            desc: "Annually payout",
        }, {
            label: "Semi annually payout",
            value: "Semi annually payout",
            desc: "Semi annually payout",
        }, {
            label: "Quarterly payout",
            value: "Quarterly payout",
            desc: "Quarterly payout",
        }, {
            label: "Monthly payout",
            value: "Monthly payout",
            desc: "Monthly payout",
        }, {
            label: "Customized",
            value: "Customized",
            desc: "Customized",
        }]

    console.log(frequency)
    function canSubmit() {
        var invalidFields = []
        if (accountIdSelected < 0) {
            invalidFields.push("Please select account.")
        }

        if (fundIdSelected < 0) {
            invalidFields.push("Please select fund.")
        }

        if (method === "") {
            invalidFields.push("Please select method.")
        }

        if (amount === 0) {
            invalidFields.push("Please enter amount.")
        }

        if (startDate === "") {
            invalidFields.push("Please select start date.")
        }

        if (endDate === "") {
            invalidFields.push("Please select end date.")
        }

        if (interest === 0) {
            invalidFields.push("Please enter annual interest rate.")
        }

        if (frequency === "") {
            invalidFields.push("Please enter payout frequency.")
        }

        setCreationErrorMessages(invalidFields)
        return invalidFields.length === 0;
    }

    async function saveInvestment() {
        if (canSubmit() === false) {
            return
        }
        // additional: record_id, Request No., lark approval link, SourceID
        const body = JSON.stringify({
            // investorIdSelected,
            accountIdSelected,
            fundIdSelected,
            method,
            "amount": parseFloat(amount),
            startDate,
            endDate,
            interest: parseFloat(interest),
            overallInterest: parseFloat(overallInterest) || 0,
            frequency,
            account,
            type,
            subtype,
            publicFundName,
            larkTableRecordId: investment.record_id, // for writing back to lark table
            larkApprovalRequestNumber: investment.fields['Request No.'].text, // for retrieving record in lark table
            larkApprovalLink: investment.fields['Request No.'].link, // to access actual lark approval instance
            larkApprovalSourceId: investment.fields.SourceID[0].text
        })

        const options = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${accessToken}`,
            },
            body
        }
        var newId = 0
        setLoading(true)

        const response = await fetch(api.lark.investments, options); // new record
        newId = await response.json()
        if (newId === 0) {
            setManageRecordId(0)
            setLoading(false)
            return
        }
        
        setManageRecordId(newId)
        fetchInvestment(newId)
        setEditMode(false)
        setSource("Database")
    }

    if (investment) {
        console.log(investment.fields)
        // record_id, Request No., lark approval link, SourceID
        // console.log("record_id", investment.record_id)
        console.log("Request No.", investment.fields['Request No.'].text)
        // console.log("lark approval link", investment.fields['Request No.'].link)
        // console.log("SourceID", investment.fields.SourceID[0].text)
    }

    return <>
        {loading && <div style={{ marginTop: "30px", textAlign: "center" }}><Spin /></div>}
        {!loading &&
            <div>
                <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                    <img style={{ width: "15px", height: "20px", cursor: "pointer" }}
                        src={backArrow}
                        onClick={() => {
                            setSource("Database")
                            setManageRecordId(0)
                        }}
                    ></img>
                </div>

                {creationErrorMessages.map((message, idx) => {
                    return <div key={idx} style={{ color: "red", marginLeft: "20px" }}>{message}</div>
                })}

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Account<span style={{ color: "red" }}>*</span>:</div>
                    <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select investor"
                            defaultValue={[]}
                            onChange={handleChangeAccount}
                            options={accountSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                    {investment && `(${larkTextFieldToString(investment.fields.Investor)})`}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Fund<span style={{ color: "red" }}>*</span>:</div>

                    <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select fund"
                            defaultValue={investment === null ? [] : investment.fund_name}
                            onChange={handleChangeFund}
                            options={fundSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                    {investment && `(${larkTextFieldToString(investment.fields.Project)})`}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Public Fund Name:</div>

                    <input value={publicFundName} style={{ ...inputStyle, ...{ width: "150px" } }}
                        onInput={e => setPublicFundName(e.target.value)}>
                    </input>

                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Type:</div>
                    <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select type"
                            value={type || []}
                            onChange={handleChangeType}
                            options={typeSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                    {investment && `(${investment.fields['Invest Type']})`}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Subtype:</div>
                    <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select subtype"
                            value={subtype || []}
                            onChange={setSubtype}
                            options={type.length > 0 ? subTypes[type.toLowerCase()].map(item => (
                                {
                                    label: item,
                                    value: item,
                                    desc: item
                                })) : []}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Method<span style={{ color: "red" }}>*</span>:</div>
                    <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={{ width: '300px', marginLeft: "5px" }}
                            placeholder="select method"
                            defaultValue={[]}
                            onChange={handleChangeMethod}
                            options={methodSelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                    {investment && `(${investment.fields['Distribution Method']})`}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Amount<span style={{ color: "red" }}>*</span>:</div>

                    <input value={amount} style={{ ...inputStyle, ...{ width: "150px" } }}
                        onInput={e => setAmount(e.target.value)}>
                    </input>

                    {amount > 0 && `(${USDollar.format(amount)})`}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Start Date<span style={{ color: "red" }}>*</span>:</div>
                    {investment === null &&
                        <input type="date" onChange={e => setStartDate(e.target.value)}></input>}
                    {investment !== null && <span>
                        <input type="date" value={startDate} onChange={e => {
                            setStartDate(e.target.value)
                        }}></input>
                    </span>}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>End Date<span style={{ color: "red" }}>*</span>:</div>
                    {investment === null &&
                        <input type="date" onChange={e => setEndDate(e.target.value)}></input>}
                    {investment !== null && <span>
                        <input type="date" value={endDate} onChange={e => {
                            setEndDateChanged(true)
                            setEndDate(e.target.value)
                        }}></input>
                    </span>}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Overall Interest Rate:</div>
                    {investment === null && <><input style={inputStyle}
                        onInput={e => setOverallInterest(e.target.value)}></input>%</>}
                    {investment !== null && <><input style={inputStyle} value={overallInterest}
                        onInput={e => setOverallInterest(e.target.value)}></input>%</>}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Calculated Interest Rate<span style={{ color: "red" }}>*</span>:</div>
                    {investment === null && <><input style={inputStyle}
                        onInput={e => setInterest(e.target.value)}></input>%</>}
                    {investment !== null && <><input style={inputStyle} value={interest}
                        onInput={e => setInterest(e.target.value)}></input>%</>}
                </div>

                <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <div>Payout Frequency<span style={{ color: "red" }}>*</span>:</div>

                    <span>
                        <Select
                            showSearch
                            mode="tag"
                            style={isPhoneSize() ? {
                                width: '300px'
                            } : {
                                width: '300px', marginLeft: "5px"
                            }}
                            placeholder="select frequency"
                            defaultValue={[]}
                            onChange={handleChangeFrequency}
                            options={frequencySelectionOptions}
                            optionRender={(option) => (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        {option.data.emoji}
                                    </span>
                                    {option.data.desc}
                                </Space>
                            )}
                        />
                    </span>
                    {investment && `(${investment.fields['Payout frequency']})`}
                </div>

                {investment && <div style={isPhoneSize() ? entryStyle : { ...entryStyle, display: "flex" }}>
                    <a href={investment.fields['Request No.']['link']} target="_blank">Lark Approval Link</a>
                </div>}

                {isAdmin() && <div style={{ margin: "20px", display: "flex", justifyContent: "space-between" }}>
                    <button style={{
                        height: "30px",
                        width: "50px",
                        borderRadius: "30px",
                        background: "rgb(17 32 86)",
                        color: "white",
                        cursor: "pointer",
                    }}
                        onClick={(e) => {
                            saveInvestment()
                        }}
                    >
                        Save
                    </button>
                </div>}
            </div>}
    </>
}