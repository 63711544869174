const api = {
    investor: {
        id: (email: string) => `/api/investor/id/${email}`,
        list: `/api/investor/list`,
        customerList: (id: number) => `/api/investor/${id}/customerList`,
        listByRole: (email: string, role: string) => `/api/investor/list/${email}/${role}`, // admin get all, normal user get users with same email
        getDebtRecord: (nameid: string) => `/api/investor/${nameid}/debt_record`, // fetchDebtRecord(3)
        getBasicInfo: (email: string) => `/api/investor/${email}/info`, // fetchInvestorBasicInfo(1)
        getBasicInfoById: (id: number) => `/api/investor/${id}/infoById`, // fetchInvestorBasicInfo(1)
        getInvestorImage: (nameid: string) => `/api/investor/${nameid}/image`,
        weightToProperties: (nameid: string) => `/api/investor/${nameid}/weight_to_property`,
        getInvestments: (nameid: string) => `/api/investor/${nameid}/investments`,
        getActiveInvestments: (nameid: number) => `/api/investor/${nameid}/investments/active`, // fetchInvestorInvestments(2)
        getActiveInvestmentsTable: (nameid: number) => `/api/investor/${nameid}/investments/active/table`, // fetchInvestorInvestments(2)
        getClosedInvestmentsTable: (nameid: number) => `/api/investor/${nameid}/investments/closed/table`, // fetchInvestorInvestments(2)
        getActiveClosedInvestments: (nameid: number) => `/api/investor/${nameid}/investments/closed`,
        getTransactions: (nameid: number) => `/api/investor/${nameid}/transactions`,
        getInvestmentFiles: (id: number) => `/api/investor/${id}/investmentFiles`,
        getDocumentsByInvestorId: (id: number) => `/api/investor/${id}/documentsByInvestorId`,
        // insertInvestor: (email: string) => `/api/investor/${nameid}/transactions`,
    },

    transaction: {
        setStatus: (id: number) => `/api/transaction/status/${id}`,
        // get all transactions from active investments
        activeTransactions: (startDate: string, endDate: string) => `/api/transaction/?startDate=${startDate}&endDate=${endDate}`,
        allActiveTransactions: `/api/transaction/all`,
    },

    fund: {
        list: `/api/fund/list`,
        investorList: (email: string) => `/api/fund/${email}/list`, // fund list of current investors
        investorListId: (id: number) => `/api/fund/${id}/listById`, // fund list of current investor with id = {id}
        uninvestedList: (email: string) => `/api/fund/${email}/uninvested-funds`,
        uninvestedListId: (id: number) => `/api/fund/${id}/uninvested-funds-id`, // by investor id
        getBasicInfo: (fund_id: number) => `/api/fund/${fund_id}/info`,
        weightToProperties: (nameid: string) => `/api/fund/${nameid}/weight_to_property`,
        getDirectInvestmentsTo: (nameid: string) => `/api/fund/${nameid}/investments`,
        getDirectActiveInvestmentsTo: (fund_id: number) => `/api/fund/${fund_id}/investments/active`,
        getFundProperty: (fundId: number) => `/api/fund/${fundId}/property`
    },

    property: {
        list: `/api/property/list`,
        getBasicInfo: (nameid: string) => `/api/property/${nameid}/info`,
    },

    file: {
        upload: `/api/file/upload`,
        investorBulkUpload: `/api/file/investorBulkUpload`,
        download: (filename: string) => `/api/file/download`,
        getPhotos: (fundId: number) => `/api/file/photo/${fundId}`,
        getPdfs: (fundId: number) => `/api/file/pdfs/${fundId}`,
        addInvestmentFiles: `/api/file/investmentFiles`,
        deleteInvestmentFiles: `/api/file/investmentFiles`,
        fetchInvestmentFiles: (investmentId: number) => `/api/file/investmentFiles/${investmentId}`,
    },

    misc: {
        getNameIDMapping: `/api/misc/nameid_mapping`,
    },

    table: {
        list: `/api/table/list`,
        getDataAll: (tableName: string) => `/api/table/${tableName}/data`,
    },
    sale: {
        customerIds: (id: number) => `/api/sale/${id}/customerIds`,
        customerAccountIds: (id: number) => `/api/sale/${id}/customerAccountIds`,
        customerFundIds: (id: number) => `/api/sale/${id}/customerFundIds`,
        customerHoldingCompanyIds: (id: number) => `/api/sale/${id}/customerHoldingCompanyIds`,
        customerAssetIds: (id: number) => `/api/sale/${id}/customerAssetIds`,
    },
    manager: {
        investors: '/api/manager/investors', // get all investors | insert new investor
        allInvestorPositions: '/api/manager/investors/allPositions',
        accountIdToInvestorsAll: `/api/manager/investors/accountIdToInvestorsAll`,
        investor: (id: number) => `/api/manager/investor/${id}`, // update an investor
        accountsByInvestor: (id: number) => `/api/manager/investor/${id}/accounts`, // get accounts held by investor
        fundsByInvestor: (id: number) => `/api/manager/investor/${id}/funds`, // get funds invested by investor
        companiesByInvestor: (id: number) => `/api/manager/investor/${id}/companies`, // get companies invested by investor
        propertiesByInvestor: (id: number) => `/api/manager/investor/${id}/properties`, // get companies invested by investor
        positionsByInvestor: (id: number) => `/api/manager/investor/${id}/positions`,

        sale: (id: number) => `/api/manager/sale/${id}`,
        customersBySale: (id: number) => `/api/manager/sale/${id}/customersBySale`,

        accounts: '/api/manager/accounts', // get all accounts | insert new account
        account: (id: number) => `/api/manager/account/${id}`, // update an account
        fundsByAccount: (id: number) => `/api/manager/account/${id}/funds`,
        investorsByAccount: (id: number) => `/api/manager/account/${id}/investors`,
        companiesByAccount: (id: number) => `/api/manager/account/${id}/companies`,
        propertiesByAccount: (id: number) => `/api/manager/account/${id}/properties`,
        positionsByAccount: (id: number) => `/api/manager/account/${id}/positions`,

        investments: `/api/manager/investments`, // get all investments | insert new investment
        investment: (id: number) => `/api/manager/investment/${id}`, // update an investment
        investmentPrincipalTakeOut: (id: number) => `/api/manager/investment/${id}/principalTakeout`,
        investmentPrincipalLeft: (id: number) => `/api/manager/investment/${id}/principal`,
        interestByInvestment: (id: number) => `/api/manager/investment/${id}/interest`,
        finalPayout: (id: number) => `/api/manager/investment/${id}/finalPayout`,
        investmentEmails: (id: number) => `/api/manager/investment/${id}/emails`,
        paidInterests: (id: number) => `/api/manager/investment/${id}/paidInterests`, // paid interests for investment id = id
        paidInterestsSince: (id: number, startDate: string) => `/api/manager/investment/${id}/paidInterestsSince?startDate=${startDate}`,
        closeInvestment: (id: number) => `/api/manager/closeInvestments/${id}`,
        canCloseInvestment: (id: number) => `/api/manager/investment/${id}/canClose`,
        investorsByInvestment: (id: number) => `/api/manager/investment/${id}/investors`,

        comments: `/api/manager/comments`,
        comment: (investmentId: number) => `/api/manager/comments/${investmentId}`,
        commentId: (id: number) => `/api/manager/comments/${id}`,

        updateInterests: `/api/manager/updateInterests`, // bulk update interests
        updatePrincipalTakeout: (id: number) => `/api/manager/updatePrincipalTakeout/${id}`, // bulk update interests

        funds: `/api/manager/funds`, // get all funds | insert new fund
        fundsWithActivePositionCount: `/api/manager/fundsWithActivePositionCount`,
        fund: (id: number) => `/api/manager/fund/${id}`, // update a fund
        HoldingCompaniesByFund: (id: number) => `/api/manager/fund/${id}/holdingCompanies`, // get companies invested by fund
        accountsByFund: (id: number) => `/api/manager/fund/${id}/accounts`,
        investorsByFund: (id: number) => `/api/manager/fund/${id}/investors`,
        propertiesByFund: (id: number) => `/api/manager/fund/${id}/properties`,
        positionsByFund: (id: number) => `/api/manager/fund/${id}/positions`,

        holdingCompanies: `/api/manager/holdingCompanies`, // get all holdingCompanies | insert new company
        holdingCompany: (id: number) => `/api/manager/holdingCompany/${id}`, // update a holdingCompany
        propertiesByHoldingCompany: (id: number) => `/api/manager/holdingCompany/${id}/properties`, // get properties invested by company
        fundByHoldingCompany: (id: number) => `/api/manager/holdingCompany/${id}/funds`, // get funds that are investing the company
        investorsByCompany: (id: number) => `/api/manager/holdingCompany/${id}/investors`, // get investors that are investing the company
        accountsByCompany: (id: number) => `/api/manager/holdingCompany/${id}/accounts`, // get accounts that are investing the company

        properties: `/api/manager/properties`, // get all properties
        property: (id: number) => `/api/manager/property/${id}`, // update a property
        holdingCompaniesByProperty: (id: number) => `/api/manager/property/${id}/holdingCompanies`, // companies that hold property
        fundsByProperty: (id: number) => `/api/manager/property/${id}/funds`, // funds that related to property
        investorsByProperty: (id: number) => `/api/manager/property/${id}/investors`, // investors that related to property
        accountsByProperty: (id: number) => `/api/manager/property/${id}/accounts`, // accounts that related to property

        downloadInvestors: `/api/manager/exportInvestorcsv`,
        positions: `/api/manager/positions`,
    },
    email: {
        subjectTemplate: "/api/email/subjectTemplate",
        contentTemplate: "/api/email/contentTemplate",
        pdfTemplate: "/api/email/generate-pdf",
        receiptPdf: "/api/email/receipt-pdf",
        sendEmail: "/api/email/send-email-with-attachments",
        emails: '/api/email/emails',
        fundName: (id: number) => `/api/email/fundName/${id}`,
        investmentAmount: (id: number) => `/api/email/investmentAmount/${id}`,
        investorNames: (id: number) => `/api/email/investorNames/${id}`,
        investmentStartingDate: (id: number) => `/api/email/investmentStartingDate/${id}`,
        emailsPaged: (pageSize: number, page: number, userEmail: string) => `/api/email/emails/${pageSize}/${page}/${userEmail}`,
        totalEmailCount: (userEmail: string) => `/api/email/emailCount/${userEmail}`,
        emailAttachments: (email: string) => `/api/email/attachments/${email}`,
    },
    lark: {
        unsyncedTransactionRequests: "/api/lark/unsynced-transaction-requests",
        investmentDetailFromLark: (requestNo: string) => `/api/lark/investment-detail-from-lark/${requestNo}`,
        syncStatus: (record_id: string) => `/api/lark/sync-status/${record_id}`,
        investments: `/api/lark/investments`, // get all investments | insert new investment
    }
};

export default api;

export type APISectionKeys = keyof typeof api;
export type APISection = typeof api[APISectionKeys];